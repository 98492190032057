import {
  VERITREE_EARTHLUNGS_PROJECT_ID,
  CARBON_POOL_PROJECT_ID,
  PLASTIC_BANK_PROJECT_ID,
  OREGON_TREES_PROJECT_ID,
  KELP_PROJECT_ID,
  WATER_PROJECT_ID,
} from '@/helpers/constants'

import type { ProjectId } from '@/helpers/constants'
import type { OffsetType } from '@/helpers/interfaces'

export const PROJECT_ID_IMPACT_TYPE_MAP: Record<ProjectId, OffsetType> = {
  [VERITREE_EARTHLUNGS_PROJECT_ID]: 'trees',
  [CARBON_POOL_PROJECT_ID]: 'carbon',
  [PLASTIC_BANK_PROJECT_ID]: 'plastic',
  [OREGON_TREES_PROJECT_ID]: 'trees',
  [KELP_PROJECT_ID]: 'kelp',
  [WATER_PROJECT_ID]: 'water',
}

export const IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP: Record<OffsetType, ProjectId> = {
  trees: VERITREE_EARTHLUNGS_PROJECT_ID,
  carbon: CARBON_POOL_PROJECT_ID,
  plastic: PLASTIC_BANK_PROJECT_ID,
  kelp: KELP_PROJECT_ID,
  water: WATER_PROJECT_ID,
}

export const IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP: Record<OffsetType, string> = {
  carbon: '#FFB339',
  trees: '#49E2A7',
  plastic: '#1FAEF0',
  kelp: '#49A29F',
  water: '#93B1FF',
}

export const IMPACT_TYPE_PIN_COLOR_MAP: Record<OffsetType, string> = {
  carbon: '#FFE7C0',
  trees: '#CBEFE1',
  plastic: '#9CD2EB',
  kelp: '#9BD3D3',
  water: '#6074FF',
}

export const IMPACT_TYPE_INACTIVE_PIN_COLOR_MAP: Record<OffsetType, string> = {
  carbon: '#FFE7C0',
  trees: '#CBEFE1',
  plastic: '#9CD2EB',
  kelp: '#9BD3D3',
  water: '#93B1FF',
}

export const PROJECT_IMPACT_COLOR_MAP: Record<OffsetType, string> = {
  carbon: '#FFE7C0',
  trees: '#CBEFE1',
  plastic: '#9CD2EB',
  kelp: '#C6DBDB',
  water: '#93B1FF',
}

export const IMPACT_BACKGROUND_ASSET_MAP: Record<OffsetType, string> = {
  trees: `url('${require('@/assets/gifs/comparisons/stat_tree_background.svg')}')`,
  carbon: `url('${require('@/assets/gifs/comparisons/stat_carbon_background.svg')}')`,
  plastic: `url('${require('@/assets/gifs/comparisons/stat_plastic_background.svg')}')`,
  kelp: `url('${require('@/assets/gifs/comparisons/stat_kelp_background.svg')}')`,
  water: `url('${require('@/assets/gifs/comparisons/stat_water_background.svg')}')`,
}
