<template>
  <div class="checkout-item">
    <div class="type">
      {{ amount }} <span style="text-transform: lowercase">x</span>
      {{
        trial && type
          ? t(snakeCase(type) + '_trial')
          : `${t(`${snakeCase(type ?? '')}`)}${country ? ` (${country})` : ''}`
      }}
    </div>
    <div v-if="currency === 'euro'" class="amount">
      {{ currencyEuro(displayedCost ?? -1) }}
    </div>
    <div v-if="currency === 'pound'" class="amount">
      {{ currencyPound(displayedCost ?? -1) }}
    </div>
    <div v-if="currency === 'dollar'" class="amount">
      {{ currencyDollar(displayedCost ?? -1) }}
    </div>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CheckoutItem',
  mixins: [Utils],
  computed: {
    isBusinessPlanAndYearly(): boolean {
      return (
        this.type === 'starterBusinessYearly' ||
        this.type === 'growthBusinessYearly' ||
        this.type === 'premiumBusinessYearly'
      )
    },
    displayedCost(): number | undefined {
      return this.isBusinessPlanAndYearly ? this.cost * 12 : this.cost
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`CheckoutItem.${key}`)
    },
  },
  props: {
    amount: {
      type: Number,
    },
    type: {
      type: String,
    },
    trial: {
      type: Boolean,
    },
    currency: {
      type: String,
    },
    cost: {
      type: Number,
      required: true,
    },
    country: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.checkout-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: solid 1px #21212150;
  font-size: 18px;
  line-height: 29px;
  color: #212121;
}

.type {
  text-transform: capitalize;
}

.amount {
  font-weight: bold;
}
</style>
