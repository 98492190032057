<template>
  <div class="carts-wrapper">
    <div class="totals-cart-wrapper">
      <template v-for="cartItem in accumulatedCartByType">
        <div v-if="!!cartItem.amount" :key="cartItem.type" class="text-number">
          <div class="number">+ {{ cartItem.amount }}</div>
          <div class="text">
            {{ $t(`${cartItem.type}.simple_unit`, cartItem.amount) }}
          </div>
        </div>
      </template>
    </div>

    <div class="button-wrapper">
      <v-btn
        class="button"
        color="#3B755F"
        height="54"
        variant="flat"
        :disabled="isDisabled"
        style="width: 100%; height: 100%; border-radius: 0"
        @click="saveCart"
      >
        <div v-if="!loading && !getMinimumCheckoutAmount" class="button-text">
          {{ currencyFormatter(total) }}

          <p class="confirm">
            {{ t('confirm') }}
          </p>
        </div>

        <div v-if="getMinimumCheckoutAmount" class="button-text">
          <span>
            {{ currencyFormatter(getMinimumCheckoutAmount) }}
          </span>
          <div class="confirm">
            {{ t('minimum') }}
          </div>
        </div>
        <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { AutomationOffset, Integration } from '@/store/integrations'
import { defineComponent } from 'vue'
import type { IntegrationPlatform } from '@/helpers/interfaces'

export default defineComponent({
  name: 'AddCart',
  mixins: [Utils],
  computed: {
    isDisabled(): boolean {
      return !!this.getMinimumCheckoutAmount || this.cart.some(({ amount }) => amount && amount < 0)
    },
    accumulatedCartByType(): AutomationOffset[] {
      return this.cart.reduce((acc, { type, amount }) => {
        const item = acc.find((item) => item.type === type)
        const amountToAdd = amount || 0

        if (!item) {
          acc.push({ type, amount: amountToAdd })
        } else {
          item.amount = item.amount ? item.amount + amountToAdd : amountToAdd
        }
        return acc
      }, [] as AutomationOffset[])
    },
    cart(): Omit<Required<AutomationOffset>, 'source'>[] {
      return this.$store.getters['getCurrentCart']
    },
    total(): number {
      return this.$store.getters['getCartTotal']
    },
    loading(): boolean {
      return this.$store.getters['getLoading']
    },
    hasOwnPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnPaidSubscriptionItem']
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
    getLastShopifyIntegration(): Integration {
      return this.$store.getters['getLastShopifyIntegration']
    },
    getIsShopifyActive(): () => boolean {
      return this.$store.getters['getIsShopifyActive']
    },
    getMinimumCheckoutAmount(): number {
      return this.$store.getters['getMinimumCheckoutAmount']
    },
  },
  methods: {
    t(key: string, amount?: number) {
      return this.$t(`AddCart.${key}`, amount ?? 0)
    },
    saveCart(): Promise<void> {
      return this.$store.dispatch('saveCart')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
})
</script>

<style lang="scss" scoped>
.carts-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.totals-cart-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.text-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f9f9f9;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid var(--ui-white);
}

.number {
  font-size: 20px;
}

.button-wrapper {
  min-width: 212px;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 15px;
}

.confirm {
  margin-top: 4px;
  margin-bottom: 0;
}

@media screen and (max-width: 475px) {
  .button-wrapper {
    min-width: 150px;
  }

  .number {
    font-size: 18px;
  }

  .text-number {
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
</style>
