<template>
  <div class="outer-wrapper">
    <div :class="account.accountType === 'ecommerce' ? 'success-e' : 'success-t'">
      <div class="success-wrapper">
        <!-- success ecommerce -->
        <div v-if="account.accountType === 'ecommerce'" class="success-ecommerce">
          <div class="header" style="text-align: left">
            {{ t('success') }}
          </div>

          <div class="subheader">
            {{ t('success_sub') }}
          </div>

          <div class="path-wrapper">
            <!-- Integrations -->
            <div class="path" @click="integration()">
              <div>
                <img
                  class="path-icon"
                  :src="require('@/assets/icons/integrations-icon.svg')"
                  alt="integrate"
                />
              </div>
              <div class="path-content">
                <div class="path-title">
                  <div>{{ t('add_integration') }}</div>
                </div>
                <div class="path-text">
                  <div>{{ t('add_integration_text') }}</div>
                </div>

                <div class="path-button-wrapper">
                  <gs-button :full-width="true" size="large" @click.prevent="integration">
                    {{ t('continue') }}
                  </gs-button>
                </div>
              </div>
            </div>

            <!-- Team -->
            <div class="path" @click="team()">
              <div>
                <img
                  class="path-icon"
                  :src="require('@/assets/icons/team-icon.svg')"
                  alt="integrate"
                />
              </div>
              <div class="path-content">
                <div class="path-title">
                  <div>{{ t('add_team') }}</div>
                </div>
                <div class="path-text">
                  <div>{{ t('add_team_text') }}</div>
                </div>

                <div class="path-button-wrapper">
                  <gs-button :full-width="true" size="large" @click.prevent="team">
                    {{ t('continue') }}
                  </gs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- success not ecommerce -->
        <div v-if="account.accountType !== 'ecommerce'">
          <div class="header">
            {{ t('thanks') }}
          </div>
          <div
            v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
            class="text"
          >
            {{ t('text1') }} <br /><br />
            {{ t('text2') }}
          </div>
          <div
            v-if="account.accountType !== 'business' && account.accountType !== 'ecommerce'"
            class="text"
          >
            {{ t('text_personal1') }} <br /><br />
            {{ t('text_personal2') }} <br /><br />
            {{ t('text_personal3') }}
          </div>

          <div class="button-wrapper">
            <gs-button :full-width="true" size="large" @click.prevent="goHome">
              {{ t('continue_dashboard') }}
            </gs-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <transition name="fade">
      <Loading v-if="loading" />
    </transition>
  </div>
</template>

<script lang="ts">
import { createSubscription, getSubscriptionItems, logOut } from '@api/index'
import Loading from '@/components/tools/Loading.vue'
import type { Account } from '@/store'
import type { NewPaidBusinessSubscriptionType } from '@/helpers/pricing'
import { defineComponent } from 'vue'
import { SetUpSocialMediaTracking } from '@/helpers/mixins/setupSocialMediaTracking'

export default defineComponent({
  name: 'CheckoutSuccessView',
  components: { Loading },
  mixins: [SetUpSocialMediaTracking],
  data() {
    return {
      loading: true,
      userCreated: false,
      count: 0,
    }
  },
  async created() {
    // looping the getUser until we have widgets, if the widget generation fails, or it is too slow, we log out the user (pepeHands)
    while (!this.account.widgets.length && this.count <= 10) {
      this.count++
      await this.delay()
      await this.setAccount()
    }
    if (!this.account.widgets.length) {
      logOut()
    } else {
      await this.addReferral()
      await this.createSubscription()
      await this.setRequiredActionAlert()
      this.loading = false
    }

    this.$socket.client.on('userCreated', this.connect)
  },
  methods: {
    t(key: string) {
      return this.$t(`CheckoutSuccessView.${key}`)
    },
    beforeDestroy() {
      this.$socket.client.off('userCreated')
    },
    connect() {
      this.userCreated = true
    },
    goHome() {
      this.$router.push('/')
    },
    integration() {
      this.$router.push('/add-impact/integrations')
    },
    team() {
      this.$router.push('/team')
    },
    delay() {
      return new Promise<void>((resolve) => setTimeout(resolve, 1000))
    },
    async createSubscription() {
      const params = new URLSearchParams(window.location.search)
      const clientSecret = params.get('setup_intent_client_secret')
      if (clientSecret) {
        const product = params.get('product') as NewPaidBusinessSubscriptionType

        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '')
        const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret)

        switch (setupIntent?.status) {
          case 'succeeded':
            await createSubscription({
              product,
              metadata: { type: 'subscription', accountId: this.account.accountId },
            })
            this.setSubscriptionItems(await getSubscriptionItems())
            await this.setAccount()
            this.setupPurchaseEvent(product, this.account.currency, setupIntent.id)
            break
          case 'processing':
          case 'requires_payment_method':
        }
      }
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    addReferral(): Promise<void> {
      return this.$store.dispatch('addReferral')
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
    setSubscriptionItems(items) {
      return this.$store.commit('setSubscriptionItems', items)
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    isActiveSubscriptionOnTrial() {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.outer-wrapper {
  padding: 8vh 2%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url('../../assets/backgrounds/success-background.svg');
  background-image: url('../../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
}

.success-t success-e {
  max-width: 900px;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.success-wrapper {
  width: 100%;
  display: flex;
  padding: 50px;
  flex-direction: column;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;
}

.success-ecommerce {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 25px;
  width: 100%;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #212121;
}

.subheader {
  text-align: left;
  color: #212121;
  opacity: 0.7;
  max-width: 600px;
}

.path-wrapper {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  margin-left: -6px;
  margin-right: -6px;
}

.path {
  border: 1px solid #ecebea;
  margin: 6px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 22px 25px 22px;
  width: 47%;
  height: 253px;
  cursor: pointer;
  transform: translate(0px, 0px);
  transition: box-shadow 200ms ease, transform 200ms ease, background-color 200ms ease,
    -webkit-transform 200ms ease;
}

.path:hover {
  -webkit-box-shadow: 0 0 35px 7px rgb(0 0 0 / 6%);
  box-shadow: 0 0 35px 7px rgb(0 0 0 / 6%);
  -webkit-transform: translate(0px, -4px);
  -ms-transform: translate(0px, -4px);
  transform: translate(0px, -4px);
}

.path-content {
  margin-left: 10px;
}

.path-title {
  color: #212121;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: left;
}

.path-text {
  color: #212121;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  opacity: 0.7;
  text-align: left;
  margin-top: 6px;
  max-width: 350px;
}

.path-button-wrapper {
  margin-top: 24px;
  width: 150px;
}

.button-wrapper {
  margin-top: 45px;
}

@media #{map-get($display-breakpoints, 'md')} {
  .success-t {
    padding: 8vh 5%;
  }
}

@media #{map-get($display-breakpoints, 'sm')} {
  .success-t {
    padding: 8vh 4%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .path {
    width: 100%;
    height: auto;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .outer-wrapper {
    padding: 0;
  }

  .success-t {
    padding: 0;
    height: 100vh;
  }

  .success-wrapper {
    justify-content: center;
  }

  // .success-wrapper {
  //   padding: 15px;
  // }
}
</style>
