import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2c1aee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-row-section" }
const _hoisted_2 = {
  key: 0,
  class: "widget-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetRowItem = _resolveComponent("WidgetRowItem")!
  const _component_WidgetPreview = _resolveComponent("WidgetPreview")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandColors, (color, index) => {
            return (_openBlock(), _createBlock(_component_WidgetPreview, {
              key: index,
              color: color,
              trigger: _ctx.widgetType?.trigger,
              "api-key": _ctx.widgetApiKey
            }, {
              widget: _withCtx(() => [
                _createVNode(_component_WidgetRowItem, {
                  color: color,
                  "flavor-text": _ctx.widgetFlavorText,
                  "widget-labels": _ctx.widgetLabels,
                  "is-used-in-dialog": true,
                  "is-icon-visible": _ctx.widgetType?.trigger === 'offsetPerCurrentCart',
                  "icon-types": _ctx.currentCartOffsetTypes
                }, null, 8, ["color", "flavor-text", "widget-labels", "is-icon-visible", "icon-types"])
              ]),
              _: 2
            }, 1032, ["color", "trigger", "api-key"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}