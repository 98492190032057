<template>
  <div class="welcome-view">
    <header class="header">
      <div class="header-content">
        <img
          alt="greenspark logo"
          height="32"
          width="160"
          class="greenspark-logo"
          :src="require('@/assets/greenspark-logo.svg')"
        />
        <router-link class="activate-link" :to="{ path: '/b/signup' }" replace>
          {{ t('activate_dashboard') }}
        </router-link>
      </div>
    </header>

    <main>
      <section v-if="showImpactCards" class="welcome-section">
        <i18n-t keypath="WelcomeView.welcome_title" tag="h1" class="welcome-title main">
          {{ t(customer.impactActionType.toLowerCase()) }}
          <span class="company-name">
            {{ t('companyName', { companyName: customer.sellerCompanyName }) }}
          </span>
        </i18n-t>

        <ImpactCards :impacts="customer.impacts" theme="light" class="welcome-view-impact-cards" />
        <p class="welcome-text">
          {{ t('welcome_text') }}
        </p>
        <router-link class="activate-link" :to="{ path: '/b/signup' }" replace>
          {{ t('activate_dashboard') }}
        </router-link>
      </section>

      <section class="welcome-section">
        <h2 class="welcome-title" v-html="t('tackle_climate_change_title')" />
        <p class="welcome-text tackle_text">
          {{ t('tackle_climate_change_text') }}
        </p>

        <div class="tackle-content-wrapper">
          <img
            v-if="isMobile"
            :src="require('@/assets/images/b2b2c-welcome/b2b2c_iphone.png')"
            alt="example iphone"
            class="b2b2c_iphone"
            loading="lazy"
          />
          <div v-else class="phone-image-wrapper">
            <div class="phone-background">
              <img
                v-if="windowWidth > 660"
                :src="require('@/assets/images/b2b2c-welcome/phone-gif.gif')"
                alt="example iphone"
                class="b2b2c_iphone"
                loading="lazy"
              />
            </div>
          </div>

          <div class="list-wrapper">
            <ul class="check-list">
              <li class="check-list-item" v-html="t('checklist_text_1')" />
              <li class="check-list-item" v-html="t('checklist_text_2')" />
              <li class="check-list-item" v-html="t('checklist_text_3')" />
              <li class="check-list-item" v-html="t('checklist_text_4')" />
            </ul>
            <router-link class="activate-link" :to="{ path: '/b/signup' }" replace>
              {{ t('activate_dashboard') }}
            </router-link>
          </div>
        </div>
      </section>

      <section class="welcome-section full-width">
        <h2 class="welcome-title" v-html="t('support_title')" />

        <div class="project-image-list">
          <div class="project-link" v-for="{ logo, name } in accreditedProjects" :key="name">
            <img :src="logo" :alt="name" class="project-image" />
          </div>
        </div>
        <div class="carousel-wrapper">
          <v-carousel
            model-value="0"
            continuous
            show-arrows
            hide-delimiter-background
            height="100%"
          >
            <v-carousel-item v-for="(value, key) in allImpactData" :key="key">
              <div class="carousel-item-content-wrapper">
                <img
                  :src="getImgSrc(key)"
                  alt="project image"
                  height="492"
                  width="760"
                  class="carousel-item-image"
                />
                <div class="carousel-item-text-wrapper">
                  <h4 class="carousel-title">
                    {{ $t(`${key}.default_project_title`) }}
                  </h4>
                  <p class="carousel-text">
                    {{ $t(`${key}.default_project_text`) }}
                  </p>
                  <div class="carousel-impact-card">
                    <svg class="carousel-impact-card-icon">
                      <use :href="getImpactIconIdByType(key)" />
                    </svg>
                    <p
                      class="impact-card-text mb-0"
                      v-html="$t(`${key}.community_label`, { amount: formatNumberByLocale(value) })"
                    />
                  </div>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </section>

      <section class="welcome-section">
        <h2 class="welcome-title">
          {{ t('make_difference_title') }}
        </h2>
        <div class="impact-cards-wrapper">
          <div v-for="(type, index) in OFFSET_TYPES()" :key="index" class="impact-card">
            <svg class="impact-card-icon">
              <use :href="getImpactIconIdByType(type)" />
            </svg>
            <h3 class="impact-card-title">
              {{ $t(`${type}.ongoing_action`) }}
            </h3>
            <p class="impact-card-text">
              {{ $t(`${type}.default_project_impact_description`) }}
            </p>
          </div>
        </div>
      </section>

      <section class="welcome-section full-width">
        <div class="activation-call">
          <h2 class="activation-call-title">
            {{ t('track_impact_title') }}
          </h2>
          <router-link class="activate-link" :to="{ path: '/b/signup' }" replace>
            {{ t('activate_dashboard') }}
          </router-link>
        </div>
      </section>
    </main>
  </div>
</template>

<script lang="ts">
import ImpactCards from '@/components/common/ImpactCards.vue'
import type { Customer, OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import type { GetAllImpactDataResponse } from '@api/index'
import { getAllImpactData } from '@api/index'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { ImpactIconId } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WelcomeView',
  methods: {
    OFFSET_TYPES() {
      return OFFSET_TYPES
    },
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`WelcomeView.${key}`, params ?? {})
    },
    async setAllImpactData() {
      this.allImpactData = await getAllImpactData()
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    getImgSrc(type: OffsetType) {
      switch (type) {
        case 'trees':
          return require('@/assets/images/b2b2c-welcome/earthlungs-project.jpeg')
        case 'plastic':
          return require('@/assets/images/b2b2c-welcome/plastic-project-picture.png')
        case 'carbon':
          return require('@/assets/images/b2b2c-welcome/social-asset.png')
        case 'kelp':
          return require('@/assets/images/b2b2c-welcome/kelp-carousel.jpeg')
        case 'water':
          return require('@/assets/images/b2b2c-welcome/water-carousel.jpeg')
        default: {
          const exhaustiveCheck: never = type
          throw Error(exhaustiveCheck)
        }
      }
    },
    setCustomerData(customerId: string): Promise<void> {
      return this.$store.dispatch('setCustomerData', customerId)
    },
  },
  components: { ImpactCards },
  mixins: [Utils],
  data() {
    const allImpactData: GetAllImpactDataResponse = {} as GetAllImpactDataResponse

    return {
      allImpactData,
    }
  },
  computed: {
    showImpactCards(): boolean | '' {
      return (
        this.customer.impactActionType &&
        this.customer.sellerCompanyName &&
        this.customer.impacts.length > 0
      )
    },
    customer(): Customer {
      return this.$store.getters['getCustomerData']
    },
    getImpactIconIdByType(): (type: OffsetType) => ImpactIconId {
      return this.$store.getters['getImpactIconIdByType']
    },
    getImpactDefaultProjectPictureSrcByType(): (type: OffsetType) => string {
      return this.$store.getters['getImpactDefaultProjectPictureSrcByType']
    },
    accreditedProjects(): Record<OffsetType, { name: string; logo: string }> {
      return {
        trees: {
          logo: require('@/assets/images/b2b2c-welcome/trees.png'),
          name: this.$t('trees.default_project_title'),
        },
        carbon: {
          logo: require('@/assets/images/b2b2c-welcome/carbon.png'),
          name: this.$t('carbon.default_project_title'),
        },
        plastic: {
          logo: require('@/assets/images/b2b2c-welcome/plastic.png'),
          name: this.$t('plastic.default_project_title'),
        },
        kelp: {
          logo: require('@/assets/images/b2b2c-welcome/kelp.jpg'),
          name: this.$t('kelp.default_project_title'),
        },
        water: {
          logo: require('@/assets/images/b2b2c-welcome/water.png'),
          name: this.$t('water.default_project_title'),
        },
      }
    },
  },
  async created() {
    if (this.$route.query.id) {
      await this.setCustomerData(this.$route.query.id as string)
    }
    await this.setAllImpactData()
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.header {
  background-color: var(--ui-white);
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
  box-shadow: var(--box-shadow-light);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.greenspark-logo {
  height: 24px;
  width: 120px;
}

.activate-link {
  border-radius: var(--border-radius-small);
  background-color: var(--ui-green);
  color: var(--ui-beige);
  box-shadow: var(--box-shadow);
  text-decoration: none;
  font-weight: 600;
  display: inline-flex;
  font-size: 20px;
  line-height: 24px;
  padding: 12px 30px;
  align-items: center;
  justify-content: center;
}

header .activate-link {
  padding: 10px;
  font-size: 16px;
}

.welcome-view {
  background: url('../assets/backgrounds/full-width-banner-background.svg') no-repeat bottom/contain
    var(--ui-beige);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 200px;
  min-height: 100vh;
}

.welcome-section {
  margin-bottom: 60px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-title {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

:deep(.green-text) {
  color: var(--ui-green);
  font-weight: bold;
}

.company-name {
  color: var(--ui-green);
}

.welcome-text {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.welcome-text.tackle_text {
  max-width: 30ch;
  margin-bottom: 0;
}

.b2b2c_iphone {
  width: calc(100% + 20px);
  height: 600px;
  object-fit: cover;
  object-position: center;
  margin: 0 -10px;
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

ul.check-list {
  margin-bottom: 10px;
  list-style-type: none;
  padding: 0 0 10px;
  width: 100%;
}

li.check-list-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--font-color-primary);
  margin-bottom: 20px;
  padding-left: 30px;
  position: relative;
}

li.check-list-item:before {
  content: '';
  background: url('../assets/icons/check_green.svg');
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
}

.project-image-list {
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.project-image {
  width: auto;
  object-fit: cover;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.project-link {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow);
  min-height: 70px;
  width: 200px;
}

.carousel-wrapper {
  width: calc(100% + 20px);
  margin: 0 -10px;
}

button.carousel {
  height: 60px;
  width: 60px;
  border-radius: 0;
  border: none;
}

button.carousel.prev {
  background: url('../assets/images/b2b2c-welcome/carousel-prev.svg') no-repeat center/cover;
}

button.carousel.next {
  background: url('../assets/images/b2b2c-welcome/carousel-next.svg') no-repeat center/cover;
}

:deep(.v-window__pre)v,
:deep(.v-window__next) {
  background: none;
  top: calc(100% - 65px);
  z-index: 2;
}

.carousel-item-image {
  height: 405px;
  width: 100%;
  object-fit: cover;
}

.carousel-item-content-wrapper {
  background-color: var(--ui-green-light);
}

.carousel-item-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px 75px;
  color: var(--font-color-primary);
  text-align: center;
  min-height: 405px;
}

.carousel-title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}

.carousel-text {
  font-size: 20px;
  line-height: 24px;
}

.carousel-impact-card {
  display: flex;
  align-items: center;
  border: 2px solid var(--ui-black);
  border-radius: var(--border-radius-big);
  padding: 10px;
  gap: 10px;
  margin-top: auto;
  text-align: left;
}

.carousel-impact-card-icon {
  height: 30px;
  width: 30px;
}

.impact-cards-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.impact-card {
  background-color: var(--ui-green);
  color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.impact-card-icon {
  height: 40px;
  width: 40px;
  margin-bottom: 20px;
}

.impact-card-title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
}

.activation-call {
  width: calc(100% + 20px);
  margin: 0 -10px;
  background: url('../assets/images/b2b2c-welcome/activation-call-background.png') no-repeat
    center/cover var(--ui-green-light);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.activation-call-title {
  max-width: 16ch;
  margin-bottom: 20px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .header {
    margin-bottom: 60px;
  }

  .welcome-section {
    max-width: 660px;
    margin: 0 auto 40px;
  }

  .welcome-section.full-width {
    max-width: none;
  }

  .greenspark-logo {
    height: auto;
    width: 160px;
  }

  .carousel-item-image {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  .welcome-title {
    font-size: 32px;
    line-height: 38px;
  }

  .welcome-title.main {
    font-size: 42px;
    line-height: 48px;
  }

  .welcome-text {
    font-size: 24px;
    line-height: 28px;
  }

  li.check-list-item {
    font-size: 24px;
    line-height: 28px;
  }

  li.check-list-item:before {
    height: 24px;
    width: 24px;
  }

  .activation-call-title {
    font-size: 32px;
    line-height: 38px;
  }

  .project-link {
    height: 74px;
    width: 200px;
  }

  .carousel-item-text-wrapper {
    min-height: 340px;
  }

  .phone-image-wrapper {
    background-image: url('../assets/images/b2b2c-welcome/b2b2c_iphone.png');
    background-position: center;
    background-size: cover;
    height: 600px;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    margin: 0 auto 40px;
  }

  .b2b2c_iphone {
    height: 489px;
    width: 244px;
    top: 56px;
    left: 241px;
    transform: rotate(-4.6deg);
    position: absolute;
    border-radius: 40px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .welcome-view {
    align-items: center;
  }

  .carousel-item-content-wrapper {
    height: 536px;
  }

  .welcome-view-impact-cards {
    width: calc(80% + 20px);
  }

  .welcome-section,
  .welcome-section.full-width {
    max-width: 1200px;
    margin: 0 auto 60px;
  }

  .impact-cards-wrapper {
    background: url('../assets/images/b2b2c-welcome/impact-cards-background.svg') no-repeat
      center/cover;
    padding: 0;
  }

  .tackle-content-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    padding: 40px 0;
    align-items: center;
    gap: 40px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }

  .check-list {
    max-width: 33ch;
  }

  .carousel-wrapper {
    border-radius: var(--border-radius-big);
    overflow: hidden;
    box-shadow: var(--box-shadow);
  }

  .carousel-item-content-wrapper {
    display: flex;
  }

  .carousel-item-image {
    height: auto;
    width: calc((100% / 3) * 2);
  }

  .carousel-item-text-wrapper {
    width: calc(100% / 3);
    text-align: left;
    height: auto;
    align-items: stretch;
    padding: 20px;
  }

  .carousel-title {
    font-size: 28px;
    line-height: 36px;
  }

  .carousel-text {
    font-size: 20px;
    line-height: 24px;
    padding-right: 70px;
  }

  .carousel-impact-card {
    margin: auto 0 0 0;
    font-size: 20px;
    line-height: 24px;
    padding: 10px;
  }

  .carousel-impact-card-icon {
    flex-shrink: 0;
    height: 45px;
    width: 45px;
  }

  :deep(.v-window__prev),
  :deep(.v-window__next) {
    background: none;
    top: calc(50% - 40px);
  }

  button.carousel {
    height: 80px;
    width: 80px;
  }

  .impact-cards-wrapper {
    padding: 40px 0;
    gap: 24px;
    max-width: 1092px;
    justify-content: center;
    background-size: contain;
  }

  .impact-card {
    width: 348px;
  }

  .activation-call {
    max-width: 1200px;
    border-radius: var(--border-radius-big);
    box-shadow: var(--box-shadow-light);
    background: url('../assets/images/b2b2c-welcome/activation-call-background-desktop.png')
      no-repeat center/cover var(--ui-green-light);
    margin-bottom: 200px;
  }

  .activation-call-title {
    font-size: 38px;
    line-height: 42px;
    max-width: none;
  }

  .phone-image-wrapper {
    background-image: url('../assets/images/b2b2c-welcome/clear-on-blob.svg');
    background-position: center;
    background-size: contain;
    height: 800px;
    width: 590px;
    flex-shrink: 0;
    position: relative;
    margin: 0;
  }

  .phone-background {
    height: 100%;
    width: 100%;
    background-image: url('../assets/images/b2b2c-welcome/phone.png');
    background-position: center;
    background-size: contain;
  }

  .b2b2c_iphone {
    height: 753px;
    width: 377px;
    top: 23px;
    left: 107px;
    transform: rotate(-4.6deg);
    position: absolute;
    border-radius: 60px;
    margin: 0;
  }
}
</style>
