import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19a69893"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "badges-main-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "how-it-works"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "accordion-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "accordion-header-wrapper" }
const _hoisted_8 = { class: "badge-container" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "accordion-header-wrapper" }
const _hoisted_11 = { class: "badge-container" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "accordion-header-wrapper" }
const _hoisted_14 = { class: "badge-container" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "accordion-header-wrapper" }
const _hoisted_18 = { class: "header-pill" }
const _hoisted_19 = { class: "header-pill" }
const _hoisted_20 = {
  key: 0,
  class: "header-pill"
}
const _hoisted_21 = { class: "badge-container tight-container" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "accordion-header-wrapper" }
const _hoisted_30 = { class: "header-pill" }
const _hoisted_31 = { class: "header-pill" }
const _hoisted_32 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HowItWorkShare = _resolveComponent("HowItWorkShare")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_WidgetPreview = _resolveComponent("WidgetPreview")!
  const _component_WidgetRow = _resolveComponent("WidgetRow")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, [
      _createElementVNode("img", {
        class: "chevron",
        alt: "chevron",
        src: require('@/assets/icons/chevron_left.svg')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
    ]),
    (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_HowItWorkShare)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_chip_group, {
      "selected-class": "active-filter",
      "model-value": _ctx.activeFilter,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = 
        (v) => {
          if (!v) return
          _ctx.openPanels = []
          _ctx.activeFilter = v
        }
      ),
      class: "filter-group"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_chip, {
          value: "all",
          class: "filter-item",
          rounded: "lg"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('all')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_chip, {
          value: "badges",
          class: "filter-item",
          rounded: "lg"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('badges')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_chip, {
          value: "banners",
          class: "filter-item",
          rounded: "lg"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('banners')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_chip, {
          value: "widgets",
          class: "filter-item",
          rounded: "lg"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('widgets')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value"]),
    (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_expansion_panels, {
              variant: "accordion",
              flat: "",
              multiple: "",
              modelValue: _ctx.openPanels,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openPanels) = $event))
            }, {
              default: _withCtx(() => [
                (_ctx.activeFilter === 'all' || _ctx.activeFilter === 'badges')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_v_expansion_panel, {
                        class: "v-expansion-panel-headers",
                        value: "badges"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/leaf-icon.svg'),
                                style: {"max-width":"25px","margin-right":"8px"}
                              }, null, 8, _hoisted_6),
                              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('static_header')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staticWidgets, (item, a) => {
                                  return (_openBlock(), _createBlock(_component_Badge, {
                                    key: a,
                                    class: "badge",
                                    image: item.image,
                                    url: item.url,
                                    "overlay-text": _ctx.t('static'),
                                    colour: 
                      item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : ''
                    ,
                                    account: _ctx.account,
                                    "is-shopify-integrated": _ctx.isShopifyIntegrated,
                                    "shopify-urls": _ctx.shopifyUrls,
                                    "from-shopify-marketplace": _ctx.fromShopifyMarketplace
                                  }, null, 8, ["image", "url", "overlay-text", "colour", "account", "is-shopify-integrated", "shopify-urls", "from-shopify-marketplace"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel, {
                        class: "v-expansion-panel-headers",
                        value: "round-badges"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/leaf-icon.svg'),
                                style: {"max-width":"25px","margin-right":"8px"}
                              }, null, 8, _hoisted_9),
                              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t('round_header')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roundWidgets, (item, a) => {
                                  return (_openBlock(), _createBlock(_component_Badge, {
                                    key: a,
                                    class: "badge",
                                    image: item.image,
                                    url: item.url,
                                    "overlay-text": _ctx.t('static'),
                                    "is-shopify-integrated": _ctx.isShopifyIntegrated,
                                    colour: 
                      item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : ''
                    ,
                                    account: _ctx.account,
                                    "shopify-urls": _ctx.shopifyUrls
                                  }, null, 8, ["image", "url", "overlay-text", "is-shopify-integrated", "colour", "account", "shopify-urls"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.activeFilter === 'all' || _ctx.activeFilter === 'widgets')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_v_expansion_panel, {
                        value: "dynamic-widgets",
                        class: "v-expansion-panel-headers"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/leaf-icon.svg'),
                                style: {"max-width":"25px","margin-right":"8px"}
                              }, null, 8, _hoisted_12),
                              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('dynamic_header')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_14, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topStatWidgets, (widget, index) => {
                                  return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                    key: index,
                                    "widget-api-key": _ctx.widgetApiKey
                                  }, {
                                    widget: _withCtx(() => [
                                      _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_15)
                                    ]),
                                    _: 2
                                  }, 1032, ["widget-api-key"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactSettingWidgets, (item, a) => {
                        return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                          key: a,
                          class: "v-expansion-panel-headers",
                          value: `widget-${item.trigger}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  src: require('@/assets/icons/leaf-icon.svg'),
                                  style: {"max-width":"25px","margin-right":"8px"}
                                }, null, 8, _hoisted_16),
                                _createElementVNode("div", _hoisted_17, [
                                  _createTextVNode(_toDisplayString(_ctx.t(`${item.trigger}SectionTitle`)) + " ", 1),
                                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('shopify_two')), 1),
                                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('widget_api')), 1),
                                  (_ctx.isRebuyCompatible(item.trigger) && _ctx.isRebuyEnabled)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.t('rebuy_smart_cart')), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_21, [
                                    (item.trigger === 'offsetPerCurrentCart')
                                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.cartWidgets, (widget, index) => {
                                          return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                            key: index,
                                            "widget-api-key": _ctx.widgetApiKey,
                                            trigger: "offsetPerCurrentCart"
                                          }, {
                                            widget: _withCtx(() => [
                                              _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_22)
                                            ]),
                                            _: 2
                                          }, 1032, ["widget-api-key"]))
                                        }), 128))
                                      : (item.trigger === 'offsetPerOrder')
                                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.perOrderWidgets, (widget, index) => {
                                            return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                              key: index,
                                              "widget-api-key": _ctx.widgetApiKey,
                                              trigger: "offsetPerOrder"
                                            }, {
                                              widget: _withCtx(() => [
                                                _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_23)
                                              ]),
                                              _: 2
                                            }, 1032, ["widget-api-key"]))
                                          }), 128))
                                        : (item.trigger === 'offsetPerProduct')
                                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.perProductWidgets, (widget, index) => {
                                              return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                key: index,
                                                "widget-api-key": _ctx.widgetApiKey,
                                                trigger: "offsetPerProduct"
                                              }, {
                                                widget: _withCtx(() => [
                                                  _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_24)
                                                ]),
                                                _: 2
                                              }, 1032, ["widget-api-key"]))
                                            }), 128))
                                          : (item.trigger === 'offsetByPercentageOrder')
                                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.perPercentageWidgets, (widget, index) => {
                                                return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                  key: index,
                                                  "widget-api-key": _ctx.widgetApiKey,
                                                  trigger: "offsetByPercentageOrder"
                                                }, {
                                                  widget: _withCtx(() => [
                                                    _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_25)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["widget-api-key"]))
                                              }), 128))
                                            : (item.trigger === 'offsetBySpend')
                                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.perSpendLevelWidgets, (widget, index) => {
                                                  return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                    key: index,
                                                    "widget-api-key": _ctx.widgetApiKey,
                                                    trigger: "offsetBySpend"
                                                  }, {
                                                    widget: _withCtx(() => [
                                                      _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_26)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["widget-api-key"]))
                                                }), 128))
                                              : (item.trigger === 'offsetByStoreRevenue')
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 5 }, _renderList(_ctx.perTieredSpendLevelWidgets, (widget, index) => {
                                                    return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                      key: index,
                                                      "widget-api-key": _ctx.widgetApiKey,
                                                      trigger: "offsetByStoreRevenue"
                                                    }, {
                                                      widget: _withCtx(() => [
                                                        _createElementVNode("div", { innerHTML: widget }, null, 8, _hoisted_27)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["widget-api-key"]))
                                                  }), 128))
                                                : _createCommentVNode("", true),
                                    (item.trigger === 'byPercentageOfRevenue')
                                      ? (_openBlock(), _createBlock(_component_WidgetRow, {
                                          key: 6,
                                          "widget-type": item,
                                          "widget-api-key": _ctx.widgetApiKey
                                        }, null, 8, ["widget-type", "widget-api-key"]))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.activeFilter === 'all' || _ctx.activeFilter === 'banners')
                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                      key: 2,
                      class: "v-expansion-panel-headers",
                      value: "banners"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: require('@/assets/icons/leaf-icon.svg'),
                              style: {"max-width":"25px","margin-right":"8px"}
                            }, null, 8, _hoisted_28),
                            _createElementVNode("div", _hoisted_29, [
                              _createTextVNode(_toDisplayString(_ctx.t('full_width_banner_header')) + " ", 1),
                              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('shopify_two')), 1),
                              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('widget_api')), 1)
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullWidthBannerWidgets, (widget, index) => {
                                return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                  key: index,
                                  "widget-api-key": _ctx.widgetApiKey,
                                  trigger: "banner"
                                }, {
                                  widget: _withCtx(() => [
                                    _createElementVNode("div", {
                                      innerHTML: widget,
                                      style: {"width":"100%"}
                                    }, null, 8, _hoisted_32)
                                  ]),
                                  _: 2
                                }, 1032, ["widget-api-key"]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}