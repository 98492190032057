<template>
  <div class="total-wrapper">
    <!-- Team Members  -->
    <div class="stat">
      <div class="text-number-wrapper">
        <!-- TODO: Add Dynamic Number -->
        <div class="text">
          {{ tc('member', totalMembers) }}
        </div>
        <div class="number-members">
          {{ formatNumberByLocale(totalMembers) }}
        </div>
      </div>
    </div>

    <div class="sign">x</div>

    <div v-for="(type, index) in SUBSCRIPTION_OFFSET_TYPES()" :key="index" :class="['stat', type]">
      <div class="text-number-wrapper">
        <div class="number">
          {{ formatNumberByLocale(getPriceByOffsetType(type)) }}
        </div>
        <div class="text">
          {{ tc(type, getPriceByOffsetType(type)) }}
        </div>
      </div>
    </div>

    <div class="sign">=</div>

    <!-- Total Cost  -->
    <div class="stat stat-total">
      <div class="text-number-wrapper">
        <div class="text-total">
          {{ t('total') }}
        </div>
        <div v-if="account.currency === 'euro'" class="number-total">
          {{ currencyEuro(totalCost) }} {{ getUserCurrencyCode }}
        </div>
        <div v-if="account.currency === 'pound'" class="number-total">
          {{ currencyPound(totalCost) }} {{ getUserCurrencyCode }}
        </div>
        <div v-if="account.currency === 'dollar'" class="number-total">
          {{ currencyDollar(totalCost) }} {{ getUserCurrencyCode }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Account } from '@/store'
import type { CurrencyCode, OffsetType } from '@/helpers/interfaces'
import { SUBSCRIPTION_OFFSET_TYPES } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'TotalsPersonal',
  mixins: [Utils],
  props: {
    personalPrice: {
      type: Number,
    },
    plasticHeroPrice: {
      type: Number,
    },
    earthHeroPrice: {
      type: Number,
    },
    progressItem: {
      type: Number,
    },
  },
  data() {
    return {
      personalCarbon: 1.42,
      personalPlastic: 100,
      plasticHeroCarbon: 1.42,
      plasticHeroPlastic: 350,
      earthHeroCarbon: 5.7,
      earthHeroPlastic: 350,
      plasticAddon: 350,
      treeAddon: 1,
    }
  },
  created() {
    this.setProjectList()
  },
  computed: {
    getUserCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
    personalAmount(): number | null {
      return this.account?.personalPlans.personal ?? null
    },
    plasticHeroAmount(): number | null {
      return this.account?.personalPlans.plasticHero ?? null
    },
    earthHeroAmount(): number | null {
      return this.account?.personalPlans.earthHero ?? null
    },
    totalMembers(): number {
      return (
        (this.personalAmount ?? 0) + (this.plasticHeroAmount ?? 0) + (this.earthHeroAmount ?? 0)
      )
    },
    totalCarbon(): number {
      return (
        (this.personalAmount ?? 0) * this.personalCarbon +
        (this.plasticHeroAmount ?? 0) * this.plasticHeroCarbon +
        (this.earthHeroAmount ?? 0) * this.earthHeroCarbon
      )
    },
    totalPlastic(): number {
      return (
        (this.personalAmount ?? 0) * this.personalPlastic +
        (this.plasticHeroAmount ?? 0) * this.plasticHeroPlastic +
        (this.earthHeroAmount ?? 0) * this.earthHeroPlastic +
        this.plasticAddonTotal
      )
    },
    totalTrees(): number {
      return this.account.personalPlanAddons.treeAddon ?? 0
    },
    totalCost(): number {
      return (
        (this.personalAmount ?? 0) * (this.personalPrice ?? 0) +
        (this.plasticHeroAmount ?? 0) * (this.plasticHeroPrice ?? 0) +
        (this.earthHeroAmount ?? 0) * (this.earthHeroPrice ?? 0) +
        (this.totalTrees ?? 0) * (this.getDefaultProjectPriceByType('trees') ?? 0) +
        (this.account.personalPlanAddons.plasticAddon ?? 0) * this.plasticAddonPrice
      )
    },
    plasticAddonTotal(): number {
      return (this.account.personalPlanAddons.plasticAddon ?? 0) * this.plasticAddon
    },
    plasticAddonPrice() {
      switch (this.account.currency) {
        case 'euro':
          return 5.75
        case 'pound':
          return 5.1
        default:
          return 6.25
      }
    },
  },
  methods: {
    SUBSCRIPTION_OFFSET_TYPES() {
      return SUBSCRIPTION_OFFSET_TYPES
    },
    t(key: string) {
      return this.$t(`TotalsPersonal.${key}`)
    },
    tc(key: string, count: number) {
      return this.$t(`TotalsPersonal.${key}`, count)
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
    getPriceByOffsetType(offsetType: OffsetType): number {
      switch (offsetType) {
        case 'trees':
          return this.totalTrees
        case 'plastic':
          return this.totalPlastic
        case 'carbon':
          return this.totalCarbon
        case 'kelp':
          return 0
        case 'water':
          return 0
        default: {
          const exhaustiveCheck: never = offsetType
          throw new Error(exhaustiveCheck)
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.total-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 28px;
  justify-content: space-between;
  width: 230px;
  align-items: center;
  background: var(--ui-white);
  box-shadow: var(--box-shadow-wide);
  border-radius: 8px;
  height: 100%;
}

.stat {
  width: 100%;
  min-width: 190px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 90px;
  border-radius: 8px;
  background-color: var(--ui-beige);
  background-position: center;
  background-size: cover;
}

.stat.carbon {
  background-image: url('../../assets/gifs/comparisons/stat_carbon_background.svg');
}

.stat.plastic {
  background-image: url('../../assets/gifs/comparisons/stat_plastic_background.svg');
}

.stat.trees {
  background-image: url('../../assets/gifs/comparisons/stat_tree_background.svg');
}

.stat-total {
  background-color: var(--ui-blue);
}

.text-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  color: var(--ui-beige);
}

.number {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--font-color-primary);
}

.number-total {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--beige);
}

.number-members {
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  color: var(--font-color-primary);
}

.text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--font-color-primary);
}

.text-total {
  text-align: center;
  font-size: 16px;
  color: var(--beige);
}

.stat-icon {
  width: 55px;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .total-wrapper {
    padding: 25px 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }

  .stat {
    width: 19%;
    min-width: unset;
    margin-bottom: 10px;
  }

  .text-total {
    font-size: 18px;
  }

  .text {
    font-size: 15px;
  }

  .number {
    font-size: 20px;
  }

  .number-total {
    font-size: 24px;
  }

  .number-members {
    font-size: 24px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .total-wrapper {
    padding: 25px 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }

  .stat {
    width: 49%;
    min-width: unset;
    margin-bottom: 10px;
  }

  .sign {
    display: none;
  }

  .stat:last-of-type {
    flex-grow: 2;
  }

  .text-total {
    font-size: 18px;
  }

  .text {
    font-size: 15px;
  }

  .number {
    font-size: 20px;
  }

  .number-total {
    font-size: 24px;
  }

  .number-members {
    font-size: 24px;
  }
}
</style>
