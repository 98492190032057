<template>
  <FullWidthPanel
    :title="$t('LoyaltyPointsInfoPopup.title')"
    :description="$t('LoyaltyPointsInfoPopup.description')"
  >
    <div class="loyalty-info-content loyalty-info-content-row">
      <div class="loyalty-info-wrapper">
        <span class="loyalty-info-number">1</span>
        <div
          v-html="
            $t('LoyaltyPointsInfoPopup.first_step', {
              documentationLink: STAMPED_LOYALTY_DOCUMENTATION_URL,
            })
          "
        ></div>
      </div>
      <video
        :src="require('@/assets/videos/loyalty-info-video.mov').default"
        width="675"
        height="350"
        autoplay
        muted
        :controls="false"
        loop
      ></video>
    </div>
    <div class="loyalty-info-content">
      <div class="loyalty-info-wrapper">
        <span class="loyalty-info-number">2</span>
        <div v-html="$t('LoyaltyPointsInfoPopup.second_step')"></div>
      </div>

      <img :src="require('@/assets/images/loyalty-info-image.png')" alt="stamped admin interface" />
    </div>
    <div class="form-controls">
      <gs-button
        capitalized
        muted
        outlined
        type="secondary-v2"
        size="large"
        class="form-control"
        :uppercased="false"
        icon="mdi-chevron-left"
        @click="$emit('close')"
      >
        {{ $t('CommonUi.back') }}
      </gs-button>
      <gs-button
        full-width
        capitalized
        :uppercased="false"
        type="secondary-v2"
        size="large"
        class="form-control"
        @click="$emit('save')"
      >
        {{ $t('CommonUi.next') }}
      </gs-button>
    </div>
  </FullWidthPanel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import FullWidthPanel from '@/layouts/FullWidthPanel.vue'
import { STAMPED_LOYALTY_DOCUMENTATION_URL } from '@/helpers/constants'

export default defineComponent({
  name: 'LoyaltyPointsInfoPopup',
  emits: ['close', 'save'],
  components: {
    FullWidthPanel,
  },
  computed: {
    STAMPED_LOYALTY_DOCUMENTATION_URL() {
      return STAMPED_LOYALTY_DOCUMENTATION_URL
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

:deep(.panel__description) {
  font-size: 24px;
  line-height: 1.4;
}

.form-controls {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 24px;
}

.loyalty-info-content {
  img {
    max-width: 100%;
    height: auto;
  }

  video {
    width: 100%;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      margin-top: 24px;
    }
  }

  :deep(p) {
    font-size: 20px;
    font-weight: normal;
    line-height: 26px;
  }

  :deep(a) {
    font-weight: 700;
    text-decoration: underline;
    color: var(--ui-dark-blue);
  }
}

.loyalty-info-content-row {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    flex-direction: row;
    gap: 48px;
  }
}

.loyalty-info-wrapper {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.loyalty-info-number {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  border-radius: var(--border-radius-big);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: black;
  text-align: center;
  background-color: var(--ui-emerald-green);
}
</style>
