export interface CalendarEvent {
  startDate: string
  endDate: string
  displayDates: string
  period: 'season' | 'month' | 'day'
  name: string
  stylizedName?: string
  description: string
}

export interface Calendar {
  Q1: CalendarEvent[]
  Q2: CalendarEvent[]
  Q3: CalendarEvent[]
  Q4: CalendarEvent[]
}

export const CALENDAR_2025: Calendar = {
  Q1: [
    {
      startDate: '2025-01-01',
      endDate: '2025-02-01',
      displayDates: 'Jan',
      period: 'month',
      name: 'Veganuary',
      description:
        'Encouraging people worldwide to adopt a vegan diet for January, promoting animal welfare, health, and environmental benefits.',
    },
    {
      startDate: '2025-01-01',
      endDate: '2025-01-02',
      displayDates: 'Jan 1',
      period: 'day',
      name: "New Year's Resolutions",
      description:
        'Setting personal goals and commitments to improve health, habits, and environmental impact in the coming year.',
    },
    {
      startDate: '2025-02-02',
      endDate: '2025-02-03',
      displayDates: 'Feb 2',
      period: 'day',
      name: 'World Wetlands Day',
      description:
        'Raising global awareness about the vital role of wetlands in ecosystems, biodiversity, and human well-being.',
    },
    {
      startDate: '2025-03-03',
      endDate: '2025-03-04',
      displayDates: 'Mar 3',
      period: 'day',
      name: 'World Wildlife Day',
      description:
        "Celebrating the world's wild animals and plants, promoting their conservation, and highlighting their ecological importance.",
    },
    {
      startDate: '2025-03-18',
      endDate: '2025-03-19',
      displayDates: 'Mar 18',
      period: 'day',
      name: 'Global Recycling Day',
      description:
        'Promoting the importance of recycling to preserve natural resources, reduce waste, and support sustainable living.',
    },
    {
      startDate: '2025-03-22',
      endDate: '2025-03-23',
      displayDates: 'Mar 22',
      period: 'day',
      name: 'World Water Day',
      description:
        'Highlighting the significance of freshwater, advocating for sustainable water use, and ensuring access to clean water.',
    },
    {
      startDate: '2025-03-22',
      endDate: '2025-03-23',
      displayDates: 'Mar 22',
      period: 'day',
      name: 'Earth Hour Day',
      description:
        'Encouraging people to turn off non-essential lights for an hour to show support for the planet and raise awareness about climate change.',
    },
    {
      startDate: '2025-03-30',
      endDate: '2025-03-31',
      displayDates: 'Mar 30',
      period: 'day',
      name: 'Int. Day of Zero Waste',
      stylizedName: '<p>Int. Day of</p> Zero Waste',
      description:
        'Promoting waste reduction, sustainable resource use, and encouraging individuals and communities to adopt zero-waste lifestyles.',
    },
  ],
  Q2: [
    {
      startDate: '2025-04-22',
      endDate: '2025-04-23',
      displayDates: 'Apr 22',
      period: 'day',
      name: 'Earth Day',
      description:
        'The biggest global event for environmental protection, involving activities and campaigns to promote sustainability and ecological awareness.',
    },
    {
      startDate: '2025-04-25',
      endDate: '2025-04-26',
      displayDates: 'Apr 25',
      period: 'day',
      name: 'Arbor Day',
      description:
        'A day dedicated to encouraging the planting and care of trees to benefit the environment, improve air quality, and enhance biodiversity.',
    },
    {
      startDate: '2025-05-16',
      endDate: '2025-05-17',
      displayDates: 'May 16',
      period: 'day',
      name: 'Endangered Species Day',
      description:
        'Raising awareness about the need to protect endangered species, promoting conservation efforts, and supporting biodiversity.',
    },
    {
      startDate: '2025-06-01',
      endDate: '2025-06-02',
      displayDates: 'Jun 1',
      period: 'day',
      name: 'World Reef Awareness Day',
      description:
        'Highlighting the importance of coral reefs, their role in marine ecosystems, and the need for their protection and conservation.',
    },
    {
      startDate: '2025-06-05',
      endDate: '2025-06-06',
      displayDates: 'Jun 5',
      period: 'day',
      name: 'World Environment Day',
      description:
        'A global platform for environmental awareness and action, addressing pressing ecological issues and promoting sustainable practices.',
    },
    {
      startDate: '2025-06-08',
      endDate: '2025-06-09',
      displayDates: 'Jun 8',
      period: 'day',
      name: 'World Oceans Day',
      description:
        "Celebrating the ocean's vital role in our ecosystem, and raising awareness of the need to protect marine environments from pollution and overfishing.",
    },
    {
      startDate: '2025-06-22',
      endDate: '2025-06-23',
      displayDates: 'Jun 22',
      period: 'day',
      name: 'World Rainforest Day',
      description:
        'Promoting the preservation and restoration of rainforests, highlighting their ecological importance and the threats they face.',
    },
  ],
  Q3: [
    {
      startDate: '2025-07-01',
      endDate: '2025-09-30',
      displayDates: 'Q3',
      period: 'season',
      name: 'Wildfire season',
      description:
        'The period when wildfires are most likely to occur requiring increased awareness, preparedness, and measures to prevent and manage fires.',
    },
    {
      startDate: '2025-07-01',
      endDate: '2025-08-01',
      displayDates: 'Jul',
      period: 'month',
      name: 'Plastic Free July',
      description:
        'Encouraging individuals and communities to reduce their plastic use throughout July, promoting alternatives and sustainable habits.',
    },
    {
      startDate: '2025-07-24',
      endDate: '2025-07-25',
      displayDates: 'Jul 24',
      period: 'day',
      name: 'Earth Overshoot Day',
      description:
        "The date when humanity's resource consumption exceeds Earth's capacity to regenerate in a year, highlighting the need for sustainable living.",
    },
    {
      startDate: '2025-07-26',
      endDate: '2025-07-27',
      displayDates: 'Jul 26',
      period: 'day',
      name: 'World Mangrove Day',
      description:
        'Raising awareness about the importance of mangroves for coastal ecosystems, promoting their conservation and sustainable management.',
    },
    {
      startDate: '2025-08-09',
      endDate: '2025-08-10',
      displayDates: 'Aug 9',
      period: 'day',
      name: 'Int. Day of Indigenous Peoples',
      stylizedName: '<p>Int. Day of</p> Indigenous Peoples',
      description:
        'Celebrating indigenous cultures, advocating for their rights, and highlighting their contributions to biodiversity and environmental conservation.',
    },
    {
      startDate: '2025-09-20',
      endDate: '2025-09-21',
      displayDates: 'Sep 20',
      period: 'day',
      name: 'Clean Up the World Weekend',
      description:
        'Mobilizing communities worldwide to participate in local clean-up activities, promoting environmental stewardship and reducing waste.',
    },
    {
      startDate: '2025-09-21',
      endDate: '2025-09-28',
      displayDates: 'Sep 21-28',
      period: 'season',
      name: 'Climate Week',
      description:
        'A global platform for collaboration and action to address climate change and build a sustainable future.',
    },
    {
      startDate: '2025-09-28',
      endDate: '2025-09-29',
      displayDates: 'Sep 28',
      period: 'day',
      name: 'Green Consumer Day',
      description:
        'Promoting eco-friendly habits, encouraging sustainable choices, and raising awareness about the impact of consumption on the environment.',
    },
  ],
  Q4: [
    {
      startDate: '2025-10-04',
      endDate: '2025-10-05',
      displayDates: 'Oct 4',
      period: 'day',
      name: 'World Animal Day',
      description:
        'Raising the status of animals globally to improve welfare standards, promote protection, and celebrate the relationship between humans and animals.',
    },
    {
      startDate: '2025-10-16',
      endDate: '2025-10-17',
      displayDates: 'Oct 16',
      period: 'day',
      name: 'World Food Day',
      description:
        'Highlighting global food security, promoting sustainable agricultural practices, and addressing issues of hunger and nutrition.',
    },
    {
      startDate: '2025-10-24',
      endDate: '2025-10-25',
      displayDates: 'Oct 24',
      period: 'day',
      name: 'Int. Day of Climate Action',
      stylizedName: '<p>Int. Day of</p> Climate Action',
      description:
        'A day dedicated to taking urgent action against climate change, advocating for policies, and promoting sustainable practices.',
    },
    {
      startDate: '2025-11-10',
      endDate: '2025-11-21',
      displayDates: 'Nov 10-21',
      period: 'season',
      name: 'Cop 30',
      description:
        'The 30th session of the Conference of the Parties, focusing on international climate change negotiations and agreements.',
    },
    {
      startDate: '2025-11-28',
      endDate: '2025-11-29',
      displayDates: 'Nov 28',
      period: 'day',
      name: 'Black Friday',
      description:
        'The largest consumer shopping event of the year, often featuring significant sales, marking the beginning of the holiday shopping season.',
    },
    {
      startDate: '2025-12-01',
      endDate: '2025-12-02',
      displayDates: 'Dec 1',
      period: 'day',
      name: 'Cyber Monday',
      description:
        'An online shopping event with exclusive deals and discounts, following Black Friday, encouraging e-commerce and digital retail.',
    },
    {
      startDate: '2025-12-02',
      endDate: '2025-12-03',
      displayDates: 'Dec 2',
      period: 'day',
      name: 'Giving Tuesday',
      description:
        'A global movement encouraging people to support charitable causes, donate to nonprofits and engage in acts of kindness and generosity.',
    },
    {
      startDate: '2025-12-01',
      endDate: '2025-12-31',
      displayDates: 'Dec',
      period: 'season',
      name: 'Gifting Season',
      description:
        'The period in the build-up to the holiday season when people buy gifts for friends and family celebrating cultural and religious traditions.',
    },
  ],
}
