import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-226ffb3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "signup"
}
const _hoisted_2 = { class: "signup-wrapper" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignupEcommerce = _resolveComponent("SignupEcommerce")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            alt: "",
            class: "greenspark-logo",
            src: require('@/assets/greenspark-logo.svg')
          }, null, 8, _hoisted_3),
          _createVNode(_component_SignupEcommerce, { onBackClick: _ctx.navigateBack }, null, 8, ["onBackClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}