import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b70c532"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "water-comparison-container" }
const _hoisted_2 = { class: "comparison-data" }
const _hoisted_3 = { class: "comparison-amount" }
const _hoisted_4 = { class: "info-text" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "comparison-icon-wrapper" }
const _hoisted_7 = ["alt", "src"]
const _hoisted_8 = { class: "comparison-icon-wrapper" }
const _hoisted_9 = ["alt", "src"]
const _hoisted_10 = { class: "comparison-data" }
const _hoisted_11 = { class: "comparison-amount" }
const _hoisted_12 = { class: "info-text" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "comparison-data" }
const _hoisted_15 = { class: "comparison-amount" }
const _hoisted_16 = { class: "info-text" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "comparison-icon-wrapper" }
const _hoisted_19 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "litres-provided comparison-card",
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.water })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.litresProvided.value)), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('water.litres_provided_text')), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.litresProvided.link,
          target: "_blank",
          class: "info-sub-text"
        }, _toDisplayString(_ctx.$t('water.litres_provided_sub_text')), 9, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          class: "comparison-icon",
          alt: _ctx.$t('water.litres_provided_text'),
          src: require('@/assets/icons/comparisons/water-litres-provided.svg')
        }, null, 8, _hoisted_7)
      ])
    ], 4),
    _createElementVNode("div", {
      class: "children-with-education comparison-card",
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.water })
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          class: "comparison-icon",
          alt: _ctx.$t('water.children_with_education_text'),
          src: require('@/assets/icons/comparisons/water-children-with-education.svg')
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.childrenWithEducation.value)), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('water.children_with_education_text')), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.childrenWithEducation.link,
          target: "_blank",
          class: "info-sub-text"
        }, _toDisplayString(_ctx.$t('water.children_with_education_sub_text')), 9, _hoisted_13)
      ])
    ], 4),
    _createElementVNode("div", {
      class: "women-saved-from-fetching comparison-card",
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.water })
    }, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.womenSavedFromFetching.value)), 1),
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('water.women_saved_from_fetching_text')), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.womenSavedFromFetching.link,
          target: "_blank",
          class: "info-sub-text"
        }, _toDisplayString(_ctx.$t('water.women_saved_from_fetching_sub_text')), 9, _hoisted_17)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("img", {
          class: "comparison-icon",
          alt: _ctx.$t('water.women_saved_from_fetching_text'),
          src: require('@/assets/icons/comparisons/water-women-saved-from-fetching.svg')
        }, null, 8, _hoisted_19)
      ])
    ], 4)
  ]))
}