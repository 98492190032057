import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0580cdd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "templates" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "template-list" }
const _hoisted_7 = { class: "list-item-header" }
const _hoisted_8 = { class: "title-wrapper" }
const _hoisted_9 = { class: "template-name mb-0" }
const _hoisted_10 = {
  key: 0,
  class: "type-pill mb-0"
}
const _hoisted_11 = {
  key: 1,
  class: "custom-pill mb-0"
}
const _hoisted_12 = { class: "drawer" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["disabled", "onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "activation-wrapper" }
const _hoisted_18 = { class: "label-wrapper" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "hint" }
const _hoisted_21 = { class: "accordion-header__title" }
const _hoisted_22 = { class: "label" }
const _hoisted_23 = { class: "hint" }
const _hoisted_24 = { class: "label" }
const _hoisted_25 = { class: "template-type-wrapper" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "template-type-name" }
const _hoisted_28 = { class: "template-type-desc" }
const _hoisted_29 = { class: "label" }
const _hoisted_30 = { class: "hint" }
const _hoisted_31 = {
  key: 0,
  class: "radio-wrapper"
}
const _hoisted_32 = { class: "label" }
const _hoisted_33 = { class: "hint" }
const _hoisted_34 = { class: "accordion-header__title" }
const _hoisted_35 = { class: "label" }
const _hoisted_36 = { class: "hint" }
const _hoisted_37 = { class: "compo-input-container" }
const _hoisted_38 = {
  key: 2,
  class: "error-text email-name"
}
const _hoisted_39 = { class: "label" }
const _hoisted_40 = { class: "hint" }
const _hoisted_41 = { class: "label" }
const _hoisted_42 = { class: "hint" }
const _hoisted_43 = { class: "label" }
const _hoisted_44 = { class: "hint" }
const _hoisted_45 = { class: "hint" }
const _hoisted_46 = { class: "mb-1" }
const _hoisted_47 = { class: "mb-1" }
const _hoisted_48 = {
  key: 1,
  class: "error-message"
}
const _hoisted_49 = { class: "accordion-header__title" }
const _hoisted_50 = { class: "label" }
const _hoisted_51 = { class: "hint" }
const _hoisted_52 = {
  key: 0,
  class: "custom-header-image-wrapper"
}
const _hoisted_53 = ["src"]
const _hoisted_54 = { class: "label" }
const _hoisted_55 = { class: "hint" }
const _hoisted_56 = { class: "label" }
const _hoisted_57 = { class: "hint" }
const _hoisted_58 = { class: "label" }
const _hoisted_59 = { class: "hint" }
const _hoisted_60 = { class: "accordion-header__title" }
const _hoisted_61 = { class: "label" }
const _hoisted_62 = { class: "hint" }
const _hoisted_63 = { class: "send-test-wrapper" }
const _hoisted_64 = { class: "save-wrapper" }
const _hoisted_65 = {
  key: 2,
  class: "error-message text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_BaseAlert = _resolveComponent("BaseAlert")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_SchedulePicker = _resolveComponent("SchedulePicker")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_DateTimePicker = _resolveComponent("DateTimePicker")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_panel = _resolveComponent("panel")!
  const _component_CustomMenu = _resolveComponent("CustomMenu")!
  const _component_EmailPreview = _resolveComponent("EmailPreview")!
  const _component_VariablesPreview = _resolveComponent("VariablesPreview")!
  const _component_CallToUpgradePopup = _resolveComponent("CallToUpgradePopup")!
  const _component_DeleteDomainDialog = _resolveComponent("DeleteDomainDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_panel, {
      padding: "small",
      background: "secondary",
      class: "templates-panel"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('template_description')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.t('header_title')) + " ", 1),
            _createElementVNode("button", {
              disabled: _ctx.isAddNewOpen,
              class: "add-button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openAddNewPanel && _ctx.openAddNewPanel(...args)), ["prevent"]))
            }, " New + ", 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localEmailTemplates, (notificationTemplate, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "template-list-item"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_v_icon, {
                      class: _normalizeClass([
                    'checkmark-icon',
                    { active: notificationTemplate.isActive && !!notificationTemplate.id },
                    { new: !notificationTemplate.id },
                  ])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(notificationTemplate.isActive ? 'mdi-play-circle' : 'mdi-stop-circle'), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"]),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(!!notificationTemplate.id ? notificationTemplate.type.name : _ctx.t('new_template')), 1),
                    (notificationTemplate.type.template && !!notificationTemplate.id)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.t(notificationTemplate.type.template)), 1))
                      : _createCommentVNode("", true),
                    (notificationTemplate.isCustom)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_11, "Custom"))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_v_menu, null, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("button", _mergeProps({
                        class: "open-drawer-button",
                        ref_for: true
                      }, props), [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-dots-horizontal")
                          ]),
                          _: 1
                        })
                      ], 16)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: "drawer-button",
                          onClick: ($event: any) => (_ctx.openEditPanel(index))
                        }, [
                          _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" mdi-pencil-outline ")
                            ]),
                            _: 1
                          }),
                          _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.edit')), 1)
                        ], 8, _hoisted_13),
                        (!notificationTemplate.isActive)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              disabled: 
                      !_ctx.isDomainValid(notificationTemplate.settings.from.domain) ||
                      !notificationTemplate.settings.from.name
                    ,
                              class: "drawer-button",
                              onClick: ($event: any) => (_ctx.activateState(index))
                            }, [
                              _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" mdi-play-outline ")
                                ]),
                                _: 1
                              }),
                              _createTextVNode(" Activate ")
                            ], 8, _hoisted_14))
                          : (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              class: "drawer-button",
                              onClick: ($event: any) => (_ctx.deactivateState(index))
                            }, [
                              _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" mdi-stop-circle-outline ")
                                ]),
                                _: 1
                              }),
                              _createTextVNode(" Deactivate ")
                            ], 8, _hoisted_15)),
                        _createElementVNode("button", {
                          class: "drawer-button",
                          onClick: _withModifiers(($event: any) => (
                      _ctx.deleteTemplate(notificationTemplate.id, notificationTemplate.type.name)
                    ), ["prevent"])
                        }, [
                          _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" mdi-delete-outline ")
                            ]),
                            _: 1
                          }),
                          _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.delete')), 1)
                        ], 8, _hoisted_16)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(['input-wrapper', { 'is-open': _ctx.openedIndex === index }])
                }, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.t('activate')), 1),
                      _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t('activate_hint')), 1)
                    ]),
                    _createVNode(_component_v_switch, {
                      modelValue: notificationTemplate.isActive,
                      "onUpdate:modelValue": ($event: any) => ((notificationTemplate.isActive) = $event),
                      disabled: 
                    !_ctx.isDomainValid(notificationTemplate.settings.from.domain) &&
                    !notificationTemplate.isActive
                  ,
                      inset: "",
                      class: "activate-switch",
                      "hide-details": "",
                      color: "green",
                      onChange: ($event: any) => (
                    !notificationTemplate.isActive ? _ctx.deactivateState(index) : _ctx.activateState(index)
                  )
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
                  ]),
                  (
                  _ctx.selectableDomains.length > 0 &&
                  !notificationTemplate.isActive &&
                  _ctx.isDomainValid(notificationTemplate.settings.from.domain)
                )
                    ? (_openBlock(), _createBlock(_component_BaseAlert, {
                        key: 0,
                        variant: "info",
                        text: _ctx.t('activate_warning')
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true),
                  (
                  notificationTemplate.settings.from.domain &&
                  !_ctx.isDomainValid(notificationTemplate.settings.from.domain) &&
                  !notificationTemplate.isActive
                )
                    ? (_openBlock(), _createBlock(_component_BaseAlert, {
                        key: 1,
                        variant: "error",
                        text: _ctx.t('activate_error')
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_expansion_panels, {
                    "model-value": _ctx.panelsOpen,
                    multiple: "",
                    class: "accordion-wrapper",
                    flat: "",
                    onClick: _ctx.validateForms
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel, {
                        class: _normalizeClass(['accordion', { 'has-error': !_ctx.isTypeFormValid }])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "accordion-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.t('type')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_form, {
                                ref_for: true,
                                ref: "form",
                                modelValue: _ctx.isTypeFormValid,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isTypeFormValid) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.t('template_name')), 1),
                                  _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.t('template_name_hint')), 1),
                                  _createVNode(_component_v_text_field, {
                                    id: "template-name",
                                    modelValue: notificationTemplate.type.name,
                                    "onUpdate:modelValue": ($event: any) => ((notificationTemplate.type.name) = $event),
                                    color: "green",
                                    variant: "outlined",
                                    flat: "",
                                    rules: [_ctx.rules.maxlength(_ctx.inputMaxLength), _ctx.rules.required],
                                    counter: "",
                                    maxlength: _ctx.inputMaxLength,
                                    class: "form-input",
                                    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNameFilled = true))
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "maxlength"]),
                                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.t('template_type')), 1),
                                  _createElementVNode("div", _hoisted_25, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateTypes, (item) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: item,
                                        class: _normalizeClass([
                            'template-type',
                            { selected: item === notificationTemplate.type.template },
                          ]),
                                        onClick: _withModifiers(($event: any) => (_ctx.selectTemplateType(item, index)), ["prevent"])
                                      }, [
                                        _createVNode(_component_v_icon, { class: "material-icons-outlined template-icon" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getIconName(item)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.t(item)), 1),
                                        _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.t(item + '_desc')), 1)
                                      ], 10, _hoisted_26))
                                    }), 128))
                                  ]),
                                  (notificationTemplate.type.template !== 'nudge')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.t('trigger')), 1),
                                        _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.t('trigger_hint')), 1),
                                        _createVNode(_component_v_checkbox, {
                                          modelValue: notificationTemplate.type.isTriggerCustom,
                                          "onUpdate:modelValue": ($event: any) => ((notificationTemplate.type.isTriggerCustom) = $event),
                                          "hide-details": "",
                                          class: "mb-2",
                                          label: _ctx.t('trigger_checkbox')
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                        (notificationTemplate.type.isTriggerCustom)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                              _createVNode(_component_v_checkbox, {
                                                modelValue: _ctx.isAllSelected,
                                                "onUpdate:modelValue": [
                                                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isAllSelected) = $event)),
                                                  ($event: any) => (_ctx.selectAll($event, index))
                                                ],
                                                label: _ctx.t('all'),
                                                "hide-details": ""
                                              }, null, 8, ["modelValue", "label", "onUpdate:modelValue"]),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateTriggers, (trigger) => {
                                                return (_openBlock(), _createBlock(_component_v_checkbox, {
                                                  key: trigger,
                                                  modelValue: notificationTemplate.type.trigger,
                                                  "onUpdate:modelValue": [($event: any) => ((notificationTemplate.type.trigger) = $event), ($event: any) => (_ctx.checkAllState(index))],
                                                  label: _ctx.getTemplateTriggerLabel(trigger),
                                                  value: trigger,
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "value"]))
                                              }), 128))
                                            ]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    : _createCommentVNode("", true),
                                  (notificationTemplate.type.template === 'nudge')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.t('nudge_schedule')), 1),
                                        _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.t('nudge_schedule_hint')), 1),
                                        _createVNode(_component_v_checkbox, {
                                          modelValue: notificationTemplate.type.isScheduleCustom,
                                          "onUpdate:modelValue": ($event: any) => ((notificationTemplate.type.isScheduleCustom) = $event),
                                          "hide-details": "",
                                          class: "mb-2",
                                          label: _ctx.t('nudge_schedule_checkbox')
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                        (notificationTemplate.type.isScheduleCustom)
                                          ? (_openBlock(), _createBlock(_component_SchedulePicker, {
                                              key: 0,
                                              modelValue: notificationTemplate.type.schedule,
                                              "onUpdate:modelValue": ($event: any) => ((notificationTemplate.type.schedule) = $event)
                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["class"]),
                      _createVNode(_component_v_expansion_panel, {
                        class: _normalizeClass([
                    'accordion',
                    {
                      'has-error':
                        !_ctx.isSettingsFormValid ||
                        !_ctx.isDomainValid(notificationTemplate.settings.from.domain) ||
                        !_ctx.isEmailValid ||
                        !notificationTemplate.settings.from.name,
                    },
                  ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "accordion-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.t('settings')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_form, {
                                ref_for: true,
                                ref: "form",
                                modelValue: _ctx.isSettingsFormValid,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isSettingsFormValid) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.t('from')), 1),
                                  _createElementVNode("p", _hoisted_36, [
                                    _createTextVNode(_toDisplayString(_ctx.t('from_hint')) + " ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.t('from_hint_bold')), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_37, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass([
                            'combo-input-wrapper',
                            {
                              'has-error':
                                !_ctx.isEmailValid ||
                                !_ctx.isDomainValid(notificationTemplate.settings.from.domain) ||
                                !notificationTemplate.settings.from.name,
                            },
                          ])
                                    }, [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: notificationTemplate.settings.from.name,
                                        "onUpdate:modelValue": [($event: any) => ((notificationTemplate.settings.from.name) = $event), ($event: any) => (
                              _ctx.validateEmail(notificationTemplate.settings.from.name)
                            )],
                                        rules: [
                              _ctx.rules.required,
                              _ctx.rules.emailName,
                              _ctx.rules.maxlength(_ctx.inputMaxLength),
                            ],
                                        "hide-details": "",
                                        flat: "",
                                        color: "green",
                                        class: "combo-text",
                                        type: "text",
                                        variant: "solo",
                                        disabled: !_ctx.selectableDomains.length
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "disabled"]),
                                      _createVNode(_component_v_select, {
                                        modelValue: notificationTemplate.settings.from.domain,
                                        "onUpdate:modelValue": ($event: any) => ((notificationTemplate.settings.from.domain) = $event),
                                        placeholder: "Select a domain",
                                        flat: "",
                                        rules: [_ctx.rules.required],
                                        "hide-details": "",
                                        color: "green",
                                        class: "combo-select",
                                        variant: "solo",
                                        items: _ctx.selectableDomains,
                                        disabled: !_ctx.selectableDomains.length
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "items", "disabled"])
                                    ], 2),
                                    (!_ctx.selectableDomains.length)
                                      ? (_openBlock(), _createBlock(_component_i18n_t, {
                                          key: 0,
                                          keypath: "NotificationTemplates.no_domain_text",
                                          tag: "p",
                                          class: "error-message"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("button", {
                                              class: "no_domain_link",
                                              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('openCustomDomains')), ["prevent"]))
                                            }, _toDisplayString(_ctx.t('link_here')), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    (
                            notificationTemplate.settings.from.domain &&
                            !_ctx.isDomainValid(notificationTemplate.settings.from.domain)
                          )
                                      ? (_openBlock(), _createBlock(_component_i18n_t, {
                                          key: 1,
                                          keypath: "NotificationTemplates.domain_invalid",
                                          tag: "p",
                                          class: "error-message"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("button", {
                                              class: "no_domain_link",
                                              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$emit('openCustomDomains')), ["prevent"]))
                                            }, _toDisplayString(_ctx.t('link_here')), 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    (
                            notificationTemplate.settings.from.name &&
                            _ctx.inputMaxLength < notificationTemplate.settings.from.name.length
                          )
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_38, _toDisplayString(_ctx.$t('RulesMixin.max_length', { limit: _ctx.inputMaxLength })), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.t('alias')), 1),
                                  _createElementVNode("p", _hoisted_40, [
                                    _createTextVNode(_toDisplayString(_ctx.t('alias_hint')) + " ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.t('alias_hint_bold', {
                            default: _ctx.getDefaultTemplateValue(notificationTemplate.type.template)
                              .settings.alias,
                          })), 1)
                                  ]),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.settings.isAliasCustom,
                                    "onUpdate:modelValue": [($event: any) => ((notificationTemplate.settings.isAliasCustom) = $event), ($event: any) => (_ctx.setValuesOnCustomState(index, 'isAliasCustom'))],
                                    "hide-details": "",
                                    class: "mb-2",
                                    label: _ctx.t('custom_alias_checkbox')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                  _createVNode(_Transition, { name: "fade" }, {
                                    default: _withCtx(() => [
                                      (notificationTemplate.settings.isAliasCustom)
                                        ? (_openBlock(), _createBlock(_component_v_text_field, {
                                            key: 0,
                                            modelValue: notificationTemplate.settings.alias,
                                            "onUpdate:modelValue": ($event: any) => ((notificationTemplate.settings.alias) = $event),
                                            rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.inputMaxLength)],
                                            counter: "",
                                            maxlength: _ctx.inputMaxLength,
                                            variant: "outlined",
                                            flat: "",
                                            color: "green",
                                            class: "mb-5"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "maxlength"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createElementVNode("p", _hoisted_41, _toDisplayString(_ctx.t('subject')), 1),
                                  _createElementVNode("p", _hoisted_42, [
                                    _createTextVNode(_toDisplayString(_ctx.t('subject_hint')) + " ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.t('subject_hint_bold', {
                            default: _ctx.getDefaultTemplateValue(notificationTemplate.type.template)
                              .settings.subject,
                          })), 1)
                                  ]),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.settings.isSubjectCustom,
                                    "onUpdate:modelValue": [($event: any) => ((notificationTemplate.settings.isSubjectCustom) = $event), ($event: any) => (_ctx.setValuesOnCustomState(index, 'isSubjectCustom'))],
                                    "hide-details": "",
                                    class: "mb-2",
                                    label: _ctx.t('custom_subject_checkbox')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                  _createVNode(_Transition, { name: "fade" }, {
                                    default: _withCtx(() => [
                                      (notificationTemplate.settings.isSubjectCustom)
                                        ? (_openBlock(), _createBlock(_component_v_text_field, {
                                            key: 0,
                                            modelValue: notificationTemplate.settings.subject,
                                            "onUpdate:modelValue": ($event: any) => ((notificationTemplate.settings.subject) = $event),
                                            rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.inputMaxLength)],
                                            counter: "",
                                            maxlength: _ctx.inputMaxLength,
                                            variant: "outlined",
                                            flat: "",
                                            color: "green",
                                            class: "mb-5"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "maxlength"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.t('pre_header')), 1),
                                  _createElementVNode("p", _hoisted_44, [
                                    _createTextVNode(_toDisplayString(_ctx.t('pre_header_hint')) + " ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.t('pre_header_hint_bold', {
                            default: _ctx.getDefaultTemplateValue(notificationTemplate.type.template)
                              .settings.preheader,
                          })), 1)
                                  ]),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.settings.isPreheaderCustom,
                                    "onUpdate:modelValue": [($event: any) => ((notificationTemplate.settings.isPreheaderCustom) = $event), ($event: any) => (_ctx.setValuesOnCustomState(index, 'isPreHeaderCustom'))],
                                    "hide-details": "",
                                    class: "mb-2",
                                    label: _ctx.t('custom_pre_header_checkbox')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                  _createVNode(_Transition, { name: "fade" }, {
                                    default: _withCtx(() => [
                                      (notificationTemplate.settings.isPreheaderCustom)
                                        ? (_openBlock(), _createBlock(_component_v_text_field, {
                                            key: 0,
                                            modelValue: notificationTemplate.settings.preheader,
                                            "onUpdate:modelValue": ($event: any) => ((notificationTemplate.settings.preheader) = $event),
                                            rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.inputMaxLength)],
                                            counter: "",
                                            maxlength: _ctx.inputMaxLength,
                                            variant: "outlined",
                                            flat: "",
                                            color: "green",
                                            class: "mb-5"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "maxlength"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createElementVNode("p", {
                                    id: "schedule-date",
                                    ref_for: true,
                                    ref: "schedule-date",
                                    class: "label"
                                  }, _toDisplayString(_ctx.t('schedule')), 513),
                                  _createElementVNode("p", _hoisted_45, _toDisplayString(_ctx.t('schedule_hint')), 1),
                                  _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.t('start_date')), 1),
                                  _createVNode(_component_DateTimePicker, {
                                    modelValue: notificationTemplate.settings.schedule.startDate,
                                    "onUpdate:modelValue": ($event: any) => ((notificationTemplate.settings.schedule.startDate) = $event),
                                    "min-value": _ctx.initialDate,
                                    class: "mb-2",
                                    clearable: false,
                                    "date-picker-rules": [_ctx.rules.required]
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "min-value", "date-picker-rules"]),
                                  _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.t('end_date')), 1),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: _ctx.isEndDateAdded,
                                    "onUpdate:modelValue": [
                                      _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isEndDateAdded) = $event)),
                                      ($event: any) => (
                          _ctx.setValuesOnCustomState(index, 'isEndDateAdded'), _ctx.clearEndDate(index)
                        )
                                    ],
                                    "hide-details": "",
                                    class: "mb-2",
                                    label: _ctx.t('end_date_checkbox')
                                  }, null, 8, ["modelValue", "label", "onUpdate:modelValue"]),
                                  _createVNode(_Transition, { name: "fade" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, [
                                        (_ctx.isEndDateAdded)
                                          ? (_openBlock(), _createBlock(_component_DateTimePicker, {
                                              key: 0,
                                              modelValue: notificationTemplate.settings.schedule.endDate,
                                              "onUpdate:modelValue": ($event: any) => ((notificationTemplate.settings.schedule.endDate) = $event),
                                              "min-value": 
                              new Date(
                                notificationTemplate.settings.schedule.startDate,
                              ).toISOString()
                            ,
                                              class: "mb-2",
                                              error: !_ctx.isScheduleValid,
                                              onFocus: ($event: any) => (
                              _ctx.validateSchedule(
                                notificationTemplate.settings.schedule.startDate,
                                notificationTemplate.settings.schedule.endDate,
                              )
                            )
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "min-value", "error", "onFocus"]))
                                          : _createCommentVNode("", true),
                                        (!_ctx.isScheduleValid && _ctx.isEndDateAdded)
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_48, _toDisplayString(_ctx.t('schedule_error')), 1))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["class"]),
                      _createVNode(_component_v_expansion_panel, {
                        class: _normalizeClass(['accordion', { 'has-error': !_ctx.isContentFormValid }])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "accordion-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_49, _toDisplayString(_ctx.t('content')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_form, {
                                ref_for: true,
                                ref: "form",
                                modelValue: _ctx.isContentFormValid,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isContentFormValid) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.t('header_image')), 1),
                                  _createElementVNode("p", _hoisted_51, [
                                    _createTextVNode(_toDisplayString(_ctx.t('header_image_hint')) + " ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.t('header_image_hint_bold')), 1)
                                  ]),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.content.isHeaderImageCustom,
                                    "onUpdate:modelValue": [($event: any) => ((notificationTemplate.content.isHeaderImageCustom) = $event), ($event: any) => (_ctx.setValuesOnCustomState(index, 'isHeaderImageCustom'))],
                                    "hide-details": "",
                                    class: "mb-4",
                                    label: _ctx.t('header_image_checkbox')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                  _createVNode(_Transition, { name: "fade" }, {
                                    default: _withCtx(() => [
                                      (notificationTemplate.content.isHeaderImageCustom)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                                            _createElementVNode("img", {
                                              src: notificationTemplate.content.headerImage,
                                              alt: "asd",
                                              class: "custom-header-image-preview"
                                            }, null, 8, _hoisted_53),
                                            _createVNode(_component_gs_button, {
                                              icon: "mdi-upload",
                                              class: "upload-button",
                                              type: "secondary",
                                              onClick: _withModifiers(($event: any) => (_ctx.openUploadModal(index)), ["prevent"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" upload image ")
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createElementVNode("p", _hoisted_54, _toDisplayString(_ctx.t('body')), 1),
                                  _createElementVNode("p", _hoisted_55, _toDisplayString(_ctx.t('body_hint')), 1),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.content.isBodyCustom,
                                    "onUpdate:modelValue": [($event: any) => ((notificationTemplate.content.isBodyCustom) = $event), ($event: any) => (_ctx.setValuesOnCustomState(index, 'isBodyCustom'))],
                                    "hide-details": "",
                                    class: "mb-2",
                                    label: _ctx.t('custom_body_checkbox')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                  (notificationTemplate.content.isBodyCustom)
                                    ? (_openBlock(), _createBlock(_component_v_textarea, {
                                        key: 0,
                                        modelValue: notificationTemplate.content.body,
                                        "onUpdate:modelValue": [($event: any) => ((notificationTemplate.content.body) = $event), ($event: any) => (_ctx.validateBody(notificationTemplate.content.body))],
                                        rules: [_ctx.rules.required],
                                        error: !_ctx.isBodyValid,
                                        "error-messages": _ctx.bodyErrorMessage,
                                        variant: "outlined",
                                        color: "green",
                                        class: "intro-textarea",
                                        "no-resize": ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "error", "error-messages"]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("p", _hoisted_56, _toDisplayString(_ctx.t('overview')), 1),
                                  _createElementVNode("p", _hoisted_57, _toDisplayString(_ctx.t('overview_hint')), 1),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.content.isFooterHidden,
                                    "onUpdate:modelValue": ($event: any) => ((notificationTemplate.content.isFooterHidden) = $event),
                                    "hide-details": "",
                                    class: "mb-4",
                                    label: _ctx.t('hide_overview_checkbox')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                  _createElementVNode("p", _hoisted_58, _toDisplayString(_ctx.t('total_impact')), 1),
                                  _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.t('total_impact_hint')), 1),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: notificationTemplate.content.isTotalImpactHidden,
                                    "onUpdate:modelValue": ($event: any) => ((notificationTemplate.content.isTotalImpactHidden) = $event),
                                    "hide-details": "",
                                    class: "mb-4",
                                    label: _ctx.t('hide_total_impact')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["class"]),
                      (!!_ctx.maxCustomEmailTemplateAmount)
                        ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                            key: 0,
                            class: _normalizeClass(['accordion', { 'has-error': !_ctx.isTestEmailValid }])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_title, {
                                class: "accordion-header",
                                onClick: _ctx.validateTestEmail
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_60, _toDisplayString(_ctx.t('test')), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]),
                              _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_form, {
                                    ref_for: true,
                                    ref: "testEmailForm",
                                    modelValue: _ctx.isTestEmailValid,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isTestEmailValid) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_61, _toDisplayString(_ctx.t('send_test')), 1),
                                      _createElementVNode("p", _hoisted_62, _toDisplayString(_ctx.t('send_test_hint')), 1),
                                      _createElementVNode("div", _hoisted_63, [
                                        _createVNode(_component_v_text_field, {
                                          modelValue: _ctx.testEmailAddress,
                                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.testEmailAddress) = $event)),
                                          type: "email",
                                          variant: "outlined",
                                          "error-messages": !_ctx.isEmailTemplateFormValid ? _ctx.t('form_error_test') : '',
                                          error: !_ctx.isEmailTemplateFormValid,
                                          flat: "",
                                          color: "green",
                                          rules: [_ctx.rules.email]
                                        }, null, 8, ["modelValue", "error-messages", "error", "rules"]),
                                        _createVNode(_component_gs_button, {
                                          disabled: !_ctx.isTestEmailValid || !_ctx.isEmailTemplateFormValid || _ctx.loading,
                                          size: "large",
                                          width: "100",
                                          loading: _ctx.loading,
                                          onClick: _withModifiers(($event: any) => (_ctx.sendOutTestEmail(index)), ["prevent"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('send')), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["disabled", "loading", "onClick"])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["modelValue"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["class"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onClick"]),
                  _createElementVNode("div", _hoisted_64, [
                    _createVNode(_component_gs_button, {
                      size: "large",
                      disabled: 
                    !_ctx.isEveryFormValid ||
                    _ctx.loading ||
                    !_ctx.isDomainValid(notificationTemplate.settings.from.domain) ||
                    !notificationTemplate.settings.from.name
                  ,
                      loading: _ctx.loading,
                      onClick: ($event: any) => (_ctx.handleSaveClick(index))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "loading", "onClick"]),
                    _createVNode(_component_gs_button, {
                      size: "large",
                      type: "clear",
                      onClick: ($event: any) => (_ctx.handleCancelClick(index))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  (
                  !_ctx.isEveryFormValid || !_ctx.isDomainValid(notificationTemplate.settings.from.domain)
                )
                    ? (_openBlock(), _createElementBlock("p", _hoisted_65, _toDisplayString(_ctx.t('form_error_save')), 1))
                    : _createCommentVNode("", true)
                ], 2)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.openedIndex !== null)
      ? (_openBlock(), _createBlock(_component_panel, {
          key: 0,
          padding: "small",
          class: "email-preview-panel",
          background: "secondary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomMenu, {
              "menu-items": _ctx.menuItems,
              class: "menu-bar",
              onMenuClick: _ctx.handleMenuClick
            }, null, 8, ["menu-items", "onMenuClick"]),
            (_ctx.activeMenuItemValue === 'preview')
              ? (_openBlock(), _createBlock(_component_EmailPreview, _mergeProps({ key: 0 }, _ctx.localEmailTemplates[_ctx.openedIndex], {
                  "email-domain": _ctx.localEmailTemplates[_ctx.openedIndex].settings.from.domain,
                  "email-name": _ctx.localEmailTemplates[_ctx.openedIndex].settings.from.name,
                  "template-type": _ctx.localEmailTemplates[_ctx.openedIndex].type.template
                }), null, 16, ["email-domain", "email-name", "template-type"]))
              : _createCommentVNode("", true),
            (_ctx.activeMenuItemValue === 'variables')
              ? (_openBlock(), _createBlock(_component_VariablesPreview, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_CallToUpgradePopup, {
          key: 1,
          "popup-type": 'template',
          "is-premium-only": false,
          onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false))
        }))
      : _createCommentVNode("", true),
    (_ctx.isDeleteModalOpen)
      ? (_openBlock(), _createBlock(_component_DeleteDomainDialog, {
          key: 2,
          "delete-type": "template",
          "email-template-i-d": _ctx.templateToDelete,
          "email-template-name": _ctx.selectedEmailTemplateName,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isDeleteModalOpen = false)),
          onDeleted: _cache[13] || (_cache[13] = ($event: any) => (_ctx.refreshAfterDelete()))
        }, null, 8, ["email-template-i-d", "email-template-name"]))
      : _createCommentVNode("", true)
  ]))
}