import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd075726"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "project-info" }
const _hoisted_3 = { class: "project-title" }
const _hoisted_4 = {
  key: 0,
  class: "project-description-wrapper"
}
const _hoisted_5 = { class: "project-description" }
const _hoisted_6 = { class: "project-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['project-header', _ctx.impactType, { expanded: _ctx.headerExpanded }]),
    style: _normalizeStyle({ backgroundColor: _ctx.impactColorMapping[_ctx.impactType] })
  }, [
    _createElementVNode("img", {
      src: _ctx.imageUrl,
      alt: _ctx.impactType + '_image',
      class: "project-header-image"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", {
        class: "read-more",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleHeaderExpanded && _ctx.toggleHeaderExpanded(...args)))
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.t('read_more')), 1),
        _createVNode(_component_v_icon, { class: "chevron-icon" }, {
          default: _withCtx(() => [
            _createTextVNode(" mdi-chevron-down ")
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.headerExpanded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(`Projects.${_ctx.projectId}.summary`)), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(`Projects.${_ctx.projectId}.description`)), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 6))
}