import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-545da4ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dashboard"
}
const _hoisted_2 = {
  key: 0,
  class: "greeting"
}
const _hoisted_3 = { class: "greeting-name" }
const _hoisted_4 = { class: "greeting-text" }
const _hoisted_5 = { class: "first-row-container" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopStats = _resolveComponent("TopStats")!
  const _component_UpcomingEvents = _resolveComponent("UpcomingEvents")!
  const _component_PublicMap = _resolveComponent("PublicMap")!
  const _component_ImpactComparison = _resolveComponent("ImpactComparison")!
  const _component_Achievements = _resolveComponent("Achievements")!
  const _component_YearlyCalendar = _resolveComponent("YearlyCalendar")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_TreePlanterOverlay = _resolveComponent("TreePlanterOverlay")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hasImpactActions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('hey', { name: _ctx.displayName })), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('greeting')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_TopStats, {
            class: _normalizeClass(['top-stats', { 'only-child': !_ctx.isBusiness }])
          }, null, 8, ["class"]),
          (_ctx.isBusiness)
            ? (_openBlock(), _createBlock(_component_UpcomingEvents, {
                key: 0,
                class: "upcoming-events",
                onSeeMore: _cache[0] || (_cache[0] = () => (_ctx.showYearlyEventCalendar = true))
              }))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_PublicMap, { projects: _ctx.projects }, null, 8, ["projects"]),
        _createVNode(_component_ImpactComparison),
        _createVNode(_component_Achievements),
        _createVNode(_component_v_dialog, {
          class: "yearly-calendar-dialog",
          modelValue: _ctx.showYearlyEventCalendar,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showYearlyEventCalendar) = $event)),
          "max-width": "1200px",
          scrollable: "",
          fullscreen: _ctx.isMobile || _ctx.isMobileLarge
        }, {
          default: _withCtx(() => [
            _createVNode(_component_YearlyCalendar, {
              year: "2025",
              calendar: _ctx.calender,
              "is-closable": "",
              onClose: _cache[1] || (_cache[1] = () => (_ctx.showYearlyEventCalendar = false))
            }, null, 8, ["calendar"])
          ]),
          _: 1
        }, 8, ["modelValue", "fullscreen"]),
        _createVNode(_component_v_dialog, {
          class: "tree-planter-overlay-dialog",
          modelValue: _ctx.isFromOnboarding,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFromOnboarding) = $event)),
          fullscreen: "",
          transition: "slide-y-transition"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TreePlanterOverlay)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("img", {
          src: require('@/assets/backgrounds/dashboard-background-blob.svg'),
          class: "background-blob"
        }, null, 8, _hoisted_6)
      ]))
    : _createCommentVNode("", true)
}