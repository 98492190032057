import type {
  PERSONAL_SUBSCRIPTION_TYPES,
  TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES,
  NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES,
  FamilySubscriptionType,
} from '@/helpers/constants'
import store from '@/store'
import i18n from '@/i18n/index'

import type {
  CurrencyCode,
  Currency,
  OffsetType,
  CarbonComparison,
  TreeComparison,
  PlasticComparison,
  KelpComparison,
  WaterComparison,
  Comparisons,
  Emptyable,
} from '@/helpers/interfaces'
import { Decimal } from 'decimal.js'

export type PersonalSubscriptionType = (typeof PERSONAL_SUBSCRIPTION_TYPES)[number]
export type TechnicalAndBusinessSubscriptionType =
  (typeof TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES)[number]
export type NewPaidBusinessSubscriptionType = (typeof NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES)[number]
export type SubscriptionType =
  | TechnicalAndBusinessSubscriptionType
  | PersonalSubscriptionType
  | FamilySubscriptionType

export const currencySymbols: { [key in CurrencyCode]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  CAD: 'CA$',
  HKD: 'HK$',
  AUD: 'AUD$',
}

export const getCurrencySymbol = (currencyCode: CurrencyCode): string => {
  return (0)
    .toLocaleString('en-En', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export const formatCurrencyValueForDisplay = (value: number): string => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  })
}

export const getPlasticHeroPricePersonal = (currency: Currency): number => {
  switch (currency) {
    case 'euro':
      return 11.5
    case 'pound':
      return 9.2
    case 'dollar':
      return 12.5
    default:
      return 0
  }
}

export const getEarthHeroPrice = (currency: Currency): number => {
  switch (currency) {
    case 'euro':
      return 23.0
    case 'pound':
      return 18.6
    case 'dollar':
      return 25.0
    default:
      return 0
  }
}

export const getPersonalPrice = (currency: Currency): number => {
  switch (currency) {
    case 'euro':
      return 6.0
    case 'pound':
      return 4.8
    case 'dollar':
      return 6.5
    default:
      return 0
  }
}

export const getEcommercePrice = (currency: Currency) => {
  switch (currency) {
    case 'euro':
      return 5.0
    case 'pound':
      return 4.0
    case 'dollar':
      return 6.0
    default:
      return 0
  }
}

export const getStarterPrice = (currency: Currency, isYearly = false): number => {
  switch (currency) {
    case 'euro':
      return isYearly ? 7.0 : 9.0
    case 'pound':
      return isYearly ? 7.0 : 9.0
    case 'dollar':
      return isYearly ? 7.0 : 9.0
    default:
      return 0
  }
}

export const getGrowthPrice = (currency: Currency, isYearly = false) => {
  switch (currency) {
    case 'euro':
      return isYearly ? 29.0 : 39.0
    case 'pound':
      return isYearly ? 29.0 : 39.0
    case 'dollar':
      return isYearly ? 29.0 : 39.0
    default:
      return 0
  }
}

export const getPremiumPlanPrice = (currency: Currency, isYearly = false) => {
  switch (currency) {
    case 'euro':
      return isYearly ? 79.0 : 99.0
    case 'pound':
      return isYearly ? 79.0 : 99.0
    case 'dollar':
      return isYearly ? 79.0 : 99.0
    default:
      return 0
  }
}

export const getPlanPrice = (
  planType: PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType,
  currency: Currency,
) => {
  switch (planType) {
    case 'ecommerce':
    case 'ECOMMERCE':
      return getEcommercePrice(currency)
    case 'earthHero':
      return getEarthHeroPrice(currency)
    case 'personal':
      return getPersonalPrice(currency)
    case 'plasticHeroPersonal':
      return getPlasticHeroPricePersonal(currency)
    case 'growthBusiness':
      return getGrowthPrice(currency)
    case 'starterBusiness':
      return getStarterPrice(currency)
    case 'premiumBusiness':
      return getPremiumPlanPrice(currency)
    case 'starterBusinessYearly':
      return getStarterPrice(currency, true)
    case 'growthBusinessYearly':
      return getGrowthPrice(currency, true)
    case 'premiumBusinessYearly':
      return getPremiumPlanPrice(currency, true)
    case 'enterpriseBusiness':
      throw new Error('Enterprise plan has a custom price')
    case 'free':
    case 'zero':
    case 'freeBusiness':
    case 'businessLegacy':
      return 0
    default:
      throw new Error('exhaustiveCheck@getPlanPrice')
  }
}

export const getCarbonComparisons = (totalCarbon: number, locale: string): CarbonComparison => {
  return {
    flights: {
      value: (totalCarbon * 1000) / 932.7,
      link: 'https://www.icao.int/environmental-protection/CarbonOffset/Pages/default.aspx',
    },
    distance: {
      value: locale === 'de' ? totalCarbon * 3992 : totalCarbon * 2481,
      link: 'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#miles',
    },
    plantBased: {
      value: new Decimal(totalCarbon).dividedBy(0.8).toNumber(),
      link: 'https://iopscience.iop.org/article/10.1088/1748-9326/aa7541/pdf',
    },
  }
}

export const getTreeComparisons = (totalTrees: number): TreeComparison => {
  return {
    areaPlanted:
      totalTrees * 4 > 10000
        ? {
            value: Math.round((totalTrees * 4) / 100) / 100,
            suffix: ` ${i18n.global.t('ComparisonCardPublic.hectare')}`,
            link: 'https://explorer.land/x/project/cahonduras/site/Eet1mN/about',
          }
        : {
            value: totalTrees * 4,
            suffix: 'm²',
            link: 'https://explorer.land/x/project/cahonduras/site/Eet1mN/about',
          },
    carbonSequestered: {
      value: ((totalTrees * 4) / 10000) * 23,
      suffix: 't',
      link: 'https://winrock.org/flr-calculator/',
    },
    footballPitches: {
      value: totalTrees / 1775,
      link: 'https://i-converter.com/area/football-pitch-to-hectare',
    },
  }
}

export const getPlasticComparisons = (totalPlastic: number): PlasticComparison => {
  return {
    straws: {
      value: totalPlastic / 50 / 0.0005,
      link: 'https://consult.defra.gov.uk/waste-and-recycling/plastic-straws-stirrers-and-buds/supporting_documents/Plastic%20Straws%20Impact%20Assessment.pdf',
    },
    coffeeCups: {
      value: totalPlastic / 50 / 0.004,
      link: 'https://www.sustainabilityexchange.ac.uk/exploring_the_disposable_cup_challenge_webinar_',
    },
    bathtubs: {
      value: totalPlastic / 520,
      link: 'https://victoriaplum.com/blog/posts/what-is-a-standard-bath-size',
    },
  }
}

export const getKelpComparisons = (amount: number): KelpComparison => {
  return {
    carbonSequestered: {
      value: new Decimal(0.14).dividedBy(1000).times(amount).toDecimalPlaces(3).toNumber(),
      link: 'https://www.mckinsey.com/~/media/mckinsey/business%20functions/sustainability/our%20insights/blue%20carbon%20the%20potential%20of%20coastal%20and%20oceanic%20climate%20action/blue-carbon-the-potential-of-coastal-and-oceanic-climate-action-vf.pdf',
    }, // 1,400kg CO2 / 10,000m2 = 0.14kg CO2 / m2
    iceHockeyRinks: {
      value: new Decimal(amount)
        .dividedBy(new Decimal(100).times(1580))
        .toDecimalPlaces(3)
        .toNumber(),
      link: 'https://www.sciencedirect.com/science/article/pii/S2351989419301076#bib66',
    },
    babyWhales: {
      value: new Decimal(0.0278).times(amount).dividedBy(2267).toDecimalPlaces(3).toNumber(),
      link: 'https://research-repository.uwa.edu.au/en/publications/sea-ice-and-substratum-shape-extensive-kelp-forests-in-the-canadi',
    }, // Baby blue whale = 2267KG
  }
}

export const getWaterComparisons = (amount: number): WaterComparison => {
  return {
    litresProvided: {
      value: new Decimal(3.2).times(amount).toDecimalPlaces(3).toNumber(),
      link: 'https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/water/art-20044256#:~:text=The%20U.S.%20National%20Academies%20of,fluids%20a%20day%20for%20women',
    }, // The daily water intake for a man is 3.7 L and 2.7 for a woman. Average = 3.2 L/day.
    womenSavedFromFetching: {
      value: new Decimal(amount).dividedBy(2).toDecimalPlaces(0).toNumber(),
      link: 'https://www.unicef.org/press-releases/unicef-collecting-water-often-colossal-waste-time-women-and-girls',
    },
    childrenWithEducation: {
      value: new Decimal(0.42).times(amount).toDecimalPlaces(0).toNumber(),
      link: 'https://thewaterproject.org/why-water/education',
    }, // every day of clean water = 0.42 Children with a brighter education for one day.
  }
}

export const getComparisons = (
  totalAmount: number,
  offsetType: OffsetType,
  locale: string,
): Comparisons[keyof Comparisons] => {
  switch (offsetType) {
    case 'carbon':
      return getCarbonComparisons(totalAmount, locale)
    case 'plastic':
      return getPlasticComparisons(totalAmount)
    case 'trees':
      return getTreeComparisons(totalAmount)
    case 'kelp':
      return getKelpComparisons(totalAmount)
    case 'water':
      return getWaterComparisons(totalAmount)
    default: {
      const exhaustiveCheck: never = offsetType
      throw new Error(exhaustiveCheck)
    }
  }
}

export const isProductUpgrade = (
  fromProduct: string,
  toProduct: string,
): 'downgrade' | 'current' | 'upgrade' => {
  const subscriptionsOrder: Record<TechnicalAndBusinessSubscriptionType, number> = {
    ECOMMERCE: store.getters.getIntegrationsAmount <= 3 ? 3 : 6,
    ecommerce: store.getters.getIntegrationsAmount <= 3 ? 3 : 6,
    businessLegacy: store.getters.getIntegrationsAmount <= 3 ? 3 : 6,
    free: 0,
    freeBusiness: 0,
    zero: 0,
    starterBusiness: 1,
    starterBusinessYearly: 2,
    growthBusiness: 4,
    growthBusinessYearly: 5,
    premiumBusiness: 7,
    premiumBusinessYearly: 8,
    enterpriseBusiness: 9,
  }
  if ((subscriptionsOrder[fromProduct] || 0) === subscriptionsOrder[toProduct]) return 'current'
  if ((subscriptionsOrder[fromProduct] || 0) > subscriptionsOrder[toProduct]) return 'downgrade'
  return 'upgrade'
}

export const getCurrencyCode = (currency: Emptyable<Currency>): CurrencyCode => {
  switch (currency) {
    case 'euro':
      return 'EUR'
    case 'pound':
      return 'GBP'
    case 'dollar':
      return 'USD'
    case '':
      throw new Error('Currency is empty')
    default: {
      const exhaustiveCheck: never = currency
      throw new Error(exhaustiveCheck)
    }
  }
}
