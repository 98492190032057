<template>
  <section class="panel">
    <div class="panel__text-wrapper">
      <h1 class="panel__title">
        {{ title }}
      </h1>
      <p class="panel__description" v-html="description" />
    </div>

    <div class="panel__content">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'FullWidthPanel',
  props: {
    title: {
      type: String as PropType<string | TranslateResult>,
    },
    description: {
      type: String as PropType<string | TranslateResult>,
    },
    isSkippable: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.panel {
  background-color: var(--gray-light-FF);
  padding: 16px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  max-width: 1190px;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 20px;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 44px;
  }
}

.panel__text-wrapper {
  margin-bottom: 0;
}

.panel__title {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 16px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 48px;
    line-height: 58px;
  }
}

.panel__description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
