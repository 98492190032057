import type { PaidTechnicalAndBusinessSubscriptionType } from '@/helpers/constants'

export interface FeatureSettings {
  maxActiveIntegrationSources: number
  maxCustomDomainAmount: number
  maxCustomEmailTemplateAmount: number
  advancedAnalytics: boolean
  advancedAutomations: boolean
  dataAPI: boolean
  impactWallet: boolean
  partnerRewards: boolean
  purchaseAndAutomationsApi: boolean
  qrCode: boolean
  maxTeamMemberCount: number
  integrators: boolean
}

export const featureSettingsModule = {
  state: () =>
    ({
      maxActiveIntegrationSources: 0,
      maxCustomDomainAmount: 0,
      maxCustomEmailTemplateAmount: 0,
      advancedAnalytics: false,
      advancedAutomations: false,
      dataAPI: false,
      impactWallet: false,
      partnerRewards: false,
      purchaseAndAutomationsApi: false,
      qrCode: false,
      maxTeamMemberCount: 1,
      integrators: false,
    } as FeatureSettings),
  mutations: {
    setFeatureSettings(s, planType: PaidTechnicalAndBusinessSubscriptionType) {
      switch (planType) {
        case 'starterBusiness':
        case 'freeBusiness':
        case 'starterBusinessYearly':
          s.maxActiveIntegrationSources = 1
          s.maxCustomDomainAmount = 0
          s.maxCustomEmailTemplateAmount = 0
          s.advancedAnalytics = false
          s.advancedAutomations = false
          s.dataAPI = false
          s.impactWallet = false
          s.partnerRewards = false
          s.purchaseAndAutomationsApi = false
          s.qrCode = false
          s.maxTeamMemberCount = 1
          s.integrators = false
          break
        case 'growthBusiness':
        case 'growthBusinessYearly':
          s.maxActiveIntegrationSources = 3
          s.maxCustomDomainAmount = 1
          s.maxCustomEmailTemplateAmount = 1
          s.advancedAnalytics = true
          s.advancedAutomations = true
          s.dataAPI = false
          s.impactWallet = true
          s.partnerRewards = true
          s.purchaseAndAutomationsApi = false
          s.qrCode = true
          s.maxTeamMemberCount = 3
          s.integrators = true
          break
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          s.maxActiveIntegrationSources = Number.MAX_SAFE_INTEGER
          s.maxCustomDomainAmount = Number.MAX_SAFE_INTEGER
          s.maxCustomEmailTemplateAmount = Number.MAX_SAFE_INTEGER
          s.advancedAnalytics = true
          s.advancedAutomations = true
          s.dataAPI = true
          s.impactWallet = true
          s.partnerRewards = true
          s.purchaseAndAutomationsApi = true
          s.qrCode = true
          s.maxTeamMemberCount = 8
          s.integrators = true
          break
        case 'enterpriseBusiness':
          s.maxActiveIntegrationSources = Number.MAX_SAFE_INTEGER
          s.maxCustomDomainAmount = Number.MAX_SAFE_INTEGER
          s.maxCustomEmailTemplateAmount = Number.MAX_SAFE_INTEGER
          s.advancedAnalytics = true
          s.advancedAutomations = true
          s.dataAPI = true
          s.impactWallet = true
          s.partnerRewards = true
          s.purchaseAndAutomationsApi = true
          s.qrCode = true
          s.maxTeamMemberCount = Number.MAX_SAFE_INTEGER
          s.integrators = true
          break
        case 'ecommerce':
        case 'ECOMMERCE':
        case 'businessLegacy':
          s.maxActiveIntegrationSources = Number.MAX_SAFE_INTEGER
          s.maxCustomDomainAmount = 1
          s.maxCustomEmailTemplateAmount = 0
          s.advancedAnalytics = false
          s.advancedAutomations = true
          s.dataAPI = false
          s.impactWallet = true
          s.partnerRewards = true
          s.purchaseAndAutomationsApi = false
          s.qrCode = true
          s.maxTeamMemberCount = 1
          s.integrators = false
          break
        default:
          s.maxActiveIntegrationSources = 0
          s.maxCustomDomainAmount = 0
          s.maxCustomEmailTemplateAmount = 0
          s.advancedAnalytics = false
          s.advancedAutomations = false
          s.dataAPI = false
          s.impactWallet = false
          s.partnerRewards = false
          s.purchaseAndAutomationsApi = false
          s.qrCode = false
          s.maxTeamMemberCount = 1
          s.integrators = false
          break
      }
    },
  },
  actions: {},
  getters: {
    getImpactWalletFeatureSetting: (state: FeatureSettings): boolean => state.impactWallet,
    getMaxCustomEmailTemplateAmount: (state: FeatureSettings): number =>
      state.maxCustomEmailTemplateAmount,
    getMaxCustomDomainAmount: (state: FeatureSettings): number => state.maxCustomDomainAmount,
    getPartnerRewardsFeatureSetting: (state: FeatureSettings): boolean => state.partnerRewards,
    getMaxTeamMemberCount: (state: FeatureSettings): number => state.maxTeamMemberCount,
    getDataAPIFeatureSetting: (state: FeatureSettings): boolean => state.dataAPI,
    getIntegratorsFeatureSetting: (state: FeatureSettings): boolean => state.integrators,
    getAdvancedAnalyticsFeatureSetting: (state: FeatureSettings): boolean =>
      state.advancedAnalytics,
    getMaxActiveIntegrationSources: (state: FeatureSettings): number =>
      state.maxActiveIntegrationSources,
    getPurchaseAndAutomationsApiFeatureSetting: (state: FeatureSettings): boolean =>
      state.purchaseAndAutomationsApi,
    getAdvancedAutomationsFeatureSetting: (state: FeatureSettings): boolean =>
      state.advancedAutomations,
    getQrCodeFeatureSetting: (state: FeatureSettings): boolean => state.qrCode,
  },
}
