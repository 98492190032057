<template>
  <div class="invite-user-onboard">
    <DynamicInputGroup
      v-model="inviteEmails"
      :disabled="loading || invitationSuccess"
      :input-rules="[rules.email]"
      :input-number-limit="inviteNumberLimit"
      :input-label="t('email')"
      @validate="onFormValidate"
    />
    <span v-if="limitReached" class="invite-limit-reached-text">
      {{ t('limit_reached') }}
    </span>
    <gs-button
      v-if="!invitationSuccess"
      class="invite-user-button"
      type="primary"
      size="large"
      full-width
      :disabled="!isFormValid || loading"
      :loading="loading"
      @click.prevent="onSendInvitations"
    >
      {{ inviteUsersButtonText }}
    </gs-button>
    <div v-if="invitationSuccess" class="invitation-success">
      <img
        class="invitation-success-icon"
        :src="require('@/assets/icons/check_circle_2.svg')"
        alt="success"
      />
      <span class="invitation-success-text">{{ t('invitation_sent') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import DynamicInputGroup from '@/components/common/DynamicInputGroup.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { DynamicInputGroup },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      inviteEmails: [],
      loading: false,
      isFormValid: false,
      invitationSuccess: false,
    }
  },
  computed: {
    inviteUsersButtonText(): TranslateResult {
      return this.tc('invite_user_button_text', this.inviteEmails.length)
    },
    inviteNumberLimit(): number {
      // the user is a team member as well
      return this.maxTeamMemberCount - 1
    },
    limitReached(): boolean {
      return this.inviteEmails.length === this.inviteNumberLimit
    },
    maxTeamMemberCount(): number {
      return this.$store.getters['getMaxTeamMemberCount']
    },
    getEmailSyncAlertDismissed(): boolean {
      return this.$store.getters['getEmailSyncAlertDismissed']
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`InviteUserOnboard.${key}`, params ?? {})
    },
    tc(key: string, count: number) {
      return this.$t(`InviteUserOnboard.${key}`, count)
    },
    onFormValidate(isValid: boolean) {
      this.isFormValid = isValid
    },
    async onSendInvitations() {
      this.loading = true
      try {
        await this.inviteUsers(this.inviteEmails)
        this.invitationSuccess = true
        await this.updateOnboardingSkipped()

        setTimeout(async () => {
          if (this.getEmailSyncAlertDismissed || !this.automationName) {
            await this.$router.push({
              path: '/',
              query: { from: 'onboarding' },
            })
          } else {
            await this.$router.push({
              name: 'EmailIntegrationSyncAlert',
              query: { from: 'onboarding' },
            })
          }
        }, 1000)
      } catch (error) {
        // error handled in store action
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    inviteUsers(emails: Array<string>): Promise<Promise<void>> {
      return this.$store.dispatch('inviteUsers', emails)
    },
    updateOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('updateOnboardingSkipped')
    },
  },
  props: {
    automationName: {
      type: String,
    },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.invite-user-onboard {
  background-color: var(--ui-white);
}

.invite-limit-reached-text {
  color: var(--font-color-primary);
  opacity: 0.7;
}

.invitation-success {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.invite-user-button,
.invitation-success {
  margin-top: 44px;
}

.invitation-success-icon {
  width: 24px;
  height: auto;
}

.invitation-success-text {
  font-size: 24px;
  color: var(--ui-green);
  opacity: 0.7;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .invite-limit-reached-text {
    font-size: 16px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .invite-limit-reached-text {
    font-size: 20px;
  }
}
</style>
