import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6675f728"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "impact-comparison-v2-container section section--lg" }
const _hoisted_2 = { class: "section-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValueSelector = _resolveComponent("ValueSelector")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1)
        ]),
        _createVNode(_component_ValueSelector, {
          values: _ctx.valuesForValueSelector,
          "selected-value": _ctx.selectedImpactType ?? '',
          onSelect: _ctx.onValueSelect
        }, null, 8, ["values", "selected-value", "onSelect"]),
        (_ctx.selectedComparisonComponent && _ctx.comparisonsBySelectedType)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedComparisonComponent), {
              key: 0,
              "color-settings": _ctx.colorSettings,
              comparisons: _ctx.comparisonsBySelectedType
            }, null, 8, ["color-settings", "comparisons"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}