import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08cee5ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "popup-title" }
const _hoisted_3 = { class: "popup-description" }
const _hoisted_4 = { class: "input-wrapper" }
const _hoisted_5 = { class: "input-wrapper-link" }
const _hoisted_6 = { for: "rule-id" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { for: "rule-name" }
const _hoisted_9 = { class: "filter-button-list" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "impact-settings" }
const _hoisted_12 = { class: "markers" }
const _hoisted_13 = { class: "totals-wrapper section" }
const _hoisted_14 = { class: "cart-items-text" }
const _hoisted_15 = { class: "total-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_ImpactSettingCard = _resolveComponent("ImpactSettingCard")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_observe_intersection = _resolveDirective("observe-intersection")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    width: "1175",
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isOpen) = $event)),
    persistent: "",
    fullscreen: _ctx.$vuetify.display.xs
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
                _createElementVNode("button", {
                  class: "close-button",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"])),
                  variant: "plain"
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.t('fields.ruleId')), 1),
                    _createElementVNode("a", {
                      href: _ctx.STAMPED_LOYALTY_DOCUMENTATION_URL,
                      target: "_blank",
                      class: "documentation-url"
                    }, _toDisplayString(_ctx.t('where_to_find_rule_id')), 9, _hoisted_7)
                  ]),
                  _createVNode(_component_gs_input, {
                    modelValue: _ctx.localRule.ruleId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localRule.ruleId) = $event)),
                    id: "rule-id",
                    "hide-details": false,
                    disabled: _ctx.selectedRule?.ruleId,
                    placeholder: _ctx.t('headers.ruleId'),
                    rules: [_ctx.rules.required]
                  }, null, 8, ["modelValue", "disabled", "placeholder", "rules"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.t('fields.ruleName')), 1),
                  _createVNode(_component_gs_input, {
                    modelValue: _ctx.localRule.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localRule.name) = $event)),
                    id: "rule-name",
                    "hide-details": false,
                    placeholder: _ctx.t('headers.ruleName'),
                    rules: [_ctx.rules.required]
                  }, null, 8, ["modelValue", "placeholder", "rules"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: index,
                    class: _normalizeClass(['filter-button', { 'is-active': category === _ctx.selectedCategory }]),
                    onClick: _withModifiers(($event: any) => (_ctx.searchByCategory(category)), ["prevent"])
                  }, _toDisplayString(category === 'all' ? _ctx.$t(`ProductTable.all_projects`) : _ctx.$t(`${category}.action`)), 11, _hoisted_10))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (project, index) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_ImpactSettingCard, {
                    key: index,
                    modelValue: _ctx.projectImpacts[project.projectId],
                    "onUpdate:modelValue": [($event: any) => ((_ctx.projectImpacts[project.projectId]) = $event), _ctx.onProjectImpactUpdate],
                    position: index,
                    "project-id": project.projectId,
                    "is-button-active": !!_ctx.projectImpacts[project.projectId],
                    "max-value": _ctx.getMaxValueForProject(project.projectId)
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "position", "project-id", "is-button-active", "max-value"])), [
                    [_directive_observe_intersection, _ctx.setVisibleIndex, index]
                  ])
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (_, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(['marker-dot', { visible: index === _ctx.lastVisibleCardIndex }])
                  }, null, 2))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.getImpactSettingCartText(_ctx.impactSettings, _ctx.selectedTrigger)), 1),
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t('total', {
                price: _ctx.userCurrencySymbol + _ctx.getImpactSettingTotal(_ctx.impactSettings),
              })), 1)
              ]),
              _createVNode(_component_gs_button, {
                onClick: _withModifiers(_ctx.handleSave, ["prevent"]),
                loading: _ctx.loading,
                disabled: _ctx.loading || !_ctx.isFormValid,
                capitalized: "",
                uppercased: false,
                type: "monochrome",
                "full-width": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "loading", "disabled"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "fullscreen"]))
}