<template>
  <svg width="43" height="64" viewBox="0 0 43 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.6153 41.6049C42.469 38.2406 41.4158 34.9787 39.7483 32.0532L31.1669 17.0309L21.4299 0.17041L11.6979 17.0309L3.11645 32.0532C1.44893 34.9738 0.395758 38.2406 0.249484 41.6049C0.234856 41.9072 0.22998 42.2144 0.22998 42.5216C0.22998 54.2332 9.72316 63.7215 21.4299 63.7215C33.1416 63.7215 42.6299 54.2284 42.6299 42.5216C42.6299 42.2144 42.625 41.9072 42.6104 41.6049H42.6153Z"
      fill="#2BA9EA"
    />
    <path
      opacity="0.59"
      d="M6.32478 48.1287C7.48522 47.3681 8.73342 44.9643 9.41116 41.9511C10.2742 38.1041 9.87436 34.7398 8.51889 34.4375C8.03131 34.3302 7.48522 34.6374 6.94888 35.2469C7.3682 34.1498 7.87528 33.0772 8.46038 32.0532L14.2626 19.8589L21.469 0.17041L11.7369 17.0309L3.15551 32.0532C1.48799 34.9738 0.43482 38.2406 0.288546 41.6049C0.273919 41.9072 0.269043 42.2144 0.269043 42.5216C0.269043 54.2332 9.76222 63.7215 21.469 63.7215C22.3661 63.7215 23.2487 63.6581 24.1214 63.5509C15.5449 62.4782 8.5579 56.2859 6.32478 48.1287Z"
      fill="#173F64"
    />
    <path
      d="M38.4122 38.255C37.8418 37.2896 37.4127 42.965 35.4136 48.5088C33.4145 54.0526 36.7008 53.7503 37.7491 51.9023C38.8023 50.0544 41.4693 43.4526 38.4073 38.255H38.4122Z"
      fill="#BBE9FF"
    />
  </svg>
</template>
