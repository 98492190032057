import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd4c3ea0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-table-empty" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "impact-pill-wrapper" }
const _hoisted_4 = { class: "impact-pill-row" }
const _hoisted_5 = { class: "impact-pill-wrapper justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RuleImpactSettingsPopup = _resolveComponent("RuleImpactSettingsPopup")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.impactSettingsPopupIsOpen)
      ? (_openBlock(), _createBlock(_component_RuleImpactSettingsPopup, {
          key: 0,
          "integration-id": _ctx.integrationId,
          "selected-rule": _ctx.selectedRule,
          "rule-list": _ctx.rulesWithOffsets,
          onClose: _ctx.handleClose,
          onSave: _ctx.handleSaveRule
        }, null, 8, ["integration-id", "selected-rule", "rule-list", "onClose", "onSave"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_data_table, {
      loading: _ctx.loading,
      class: "data-table",
      items: _ctx.rulesWithOffsets,
      headers: _ctx.headers,
      "hide-default-footer": ""
    }, {
      "no-data": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_icon, { size: 24 }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-file-alert-outline")
            ]),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('RuleTable.no_data')), 1)
        ])
      ]),
      [`item.ruleId`]: _withCtx(({ item }) => [
        _createElementVNode("span", {
          onClick: _withModifiers(($event: any) => (_ctx.copyToClipboard(item)), ["prevent"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.shortenText(item.ruleId, 20)) + " ", 1),
          _createVNode(_component_v_icon, {
            size: 18,
            class: "copy ml-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.isCopied ? 'mdi-check-circle' : 'mdi-content-copy'), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_2)
      ]),
      [`item.offsetType`]: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.getRuleOffsets(item.ruleId).length <= 3)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getRuleOffsets(item.ruleId), (offset, index) => {
                  return (_openBlock(), _createBlock(_component_impact_pill, {
                    key: index,
                    class: "ma-0",
                    impact: { type: offset.type, amount: offset.amount || 0 }
                  }, null, 8, ["impact"]))
                }), 128))
              : (_openBlock(), _createBlock(_component_impact_pill, {
                  key: 1,
                  impact: {
                type: 'multiple',
                amount: _ctx.getTotalRuleOffset(_ctx.getRuleOffsets(item.ruleId)),
              }
                }, null, 8, ["impact"]))
          ])
        ])
      ]),
      [`item.cost`]: _withCtx(({ item }) => [
        _createTextVNode(_toDisplayString(_ctx.userCurrencySymbol) + _toDisplayString(_ctx.getImpactSettingTotal(item.offsets)), 1)
      ]),
      [`item.context`]: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_gs_button, {
            onClick: _withModifiers(($event: any) => (_ctx.editRule(item)), ["prevent"]),
            class: "icon",
            size: "small",
            type: "monochrome",
            outlined: "",
            icon: "mdi-pencil",
            capitalized: "",
            uppercased: false
          }, null, 8, ["onClick"]),
          _createVNode(_component_gs_button, {
            onClick: ($event: any) => (_ctx.openDeleteRuleDialog(item)),
            class: "icon",
            size: "small",
            type: "monochrome",
            outlined: "",
            icon: "mdi-delete",
            capitalized: "",
            uppercased: false
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 2
    }, 1032, ["loading", "items", "headers"]),
    _createVNode(_component_gs_button, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.impactSettingsPopupIsOpen = true), ["prevent"])),
      type: "monochrome",
      icon: "mdi-plus",
      capitalized: "",
      uppercased: false,
      "full-width": ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('RuleTable.add_new')), 1)
      ]),
      _: 1
    })
  ]))
}