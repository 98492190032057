<template>
  <section v-if="isEditing" class="impact-settings-wrapper">
    <v-form ref="form" v-model="isFormValid" class="impact-settings-form">
      <div class="question-editor">
        <gs-input
          v-model="localValue.question"
          class="question-input"
          :label="t('question')"
          :hide-details="false"
          :rules="[rules.required]"
          :disabled="questionInputDisabled"
        />
        <template v-for="(answerData, index) in localValue.answers">
          <div v-if="answerData.id !== editAnswerId" :key="answerData.id" class="answer-data-row">
            <div class="answer-data-wrapper">
              <gs-input
                class="answer-input"
                :label="t('answer')"
                :hide-details="true"
                :model-value="answerData.answer"
                disabled
              />
              <div class="answer-impacts">
                <impact-pill
                  v-for="(offset, i) in getImpactPillOffsetsFromAnswer(answerData)"
                  :key="i"
                  class="impact-pill"
                  :impact="{ type: offset.type, amount: offset.amount || 0 }"
                  theme="filled"
                />
              </div>
              <v-icon class="delete-answer-icon" @click="() => deleteAnswer(answerData.id)">
                mdi-delete
              </v-icon>
              <v-icon class="edit-answer-icon" @click="() => editAnswer(answerData.id)">
                mdi-pencil
              </v-icon>
            </div>
            <div class="cost-per-answer">
              <p>{{ getCostPerAnswerText(index) }}</p>
            </div>
          </div>
        </template>
        <gs-input
          :key="newAnswerInputId"
          v-model="newAnswer"
          :rules="[rules.required]"
          class="new-answer-input"
          :label="t('answer')"
        />
        <div class="impact-settings">
          <ImpactSettingCard
            v-for="(projectId, index) in getAppProjectIds"
            :key="index"
            v-model="impactSettings[projectId]"
            v-observe-intersection:[index]="setVisibleIndex"
            :position="index"
            :project-id="projectId"
            :is-button-active="!!impactSettings[projectId]"
          />
        </div>
        <div class="markers">
          <div
            v-for="(_, index) in getAppProjectIds"
            :key="index"
            :class="['marker-dot', { visible: index === lastVisibleCardIndex }]"
          />
        </div>

        <div class="totals-wrapper section">
          <p class="cart-items-text">
            {{ cartText }}
          </p>
          <p class="total-text">
            {{
              t('total', {
                price: userCurrencySymbol + formTotal,
              })
            }}
          </p>
        </div>

        <div v-if="!editAnswerId" class="action-button-group question-actions">
          <div class="action-buttons-line">
            <gs-button
              full-width
              type="monochrome"
              size="large"
              class="add-new-answer-button"
              :icon="editAnswerId ? '' : 'mdi-plus'"
              :disabled="isCreateUpdateAnswerButtonDisabled"
              @click="addAnswer"
            >
              {{ saveAnswerButtonText }}
            </gs-button>
          </div>
          <div class="action-buttons-line">
            <gs-button
              v-if="!hideQuestionEditCancelButton"
              class="cancel-edit-button"
              type="text"
              size="large"
              @click.prevent="cancelEdit"
            >
              {{ t('cancel') }}
            </gs-button>
            <gs-button
              full-width
              type="primary"
              size="large"
              :disabled="saveQuestionButtonDisabled"
              @click="saveQuestion"
            >
              {{ t('save_question') }}
            </gs-button>
          </div>
        </div>
        <div v-else class="action-button-group answer-edit-actions">
          <gs-button
            class="cancel-edit-button"
            type="text"
            size="large"
            @click.prevent="resetEditorState"
          >
            {{ t('cancel') }}
          </gs-button>
          <gs-button
            full-width
            type="primary"
            size="large"
            :disabled="isCreateUpdateAnswerButtonDisabled"
            @click="saveEditedAnswer"
          >
            {{ updateAnswerButtonText }}
          </gs-button>
        </div>
      </div>
    </v-form>
  </section>
  <section v-else class="question-summary">
    <table class="question-data-table">
      <thead>
        <tr>
          <th class="text-left question-th">
            {{ t('question') }}
          </th>
          <!-- empty placeholder header for copy buttons -->
          <th class="text-left copy-th" />
          <th class="text-left impact-th">
            {{ t('impact') }}
          </th>
          <th class="text-left cost-th">
            {{ t('cost') }}
          </th>
          <!-- empty placeholder header for edit button -->
          <th class="text-left edit-question-th" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="question-text">
            <v-tooltip :open-on-click="isTouch" color="black" location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  {{ localValue.question }}
                </span>
              </template>
              <span>{{ localValue.question }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-btn
              class="copy-button"
              icon
              @click="copyToClipboard('question', localValue.question)"
            >
              <img
                v-if="copiedItemIds.includes('question')"
                :src="require('@/assets/icons/check_circle_2.svg')"
                alt="copy"
              />
              <img v-else :src="require('@/assets/icons/file_copy.svg')" alt="copy" />
            </v-btn>
          </td>
          <td />
          <td />
          <td :rowspan="localValue.answers.length + 1" class="edit-question-td">
            <v-icon class="edit-answer-icon" @click="editQuestion"> mdi-pencil </v-icon>
          </td>
        </tr>
        <tr v-for="answerData in localValue.answers" :key="answerData.id">
          <td class="answer-text">
            <v-tooltip :open-on-click="isTouch" color="black" location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  {{ answerData.answer }}
                </span>
              </template>
              <span>{{ answerData.answer }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-btn
              class="copy-button"
              icon
              @click="copyToClipboard(answerData.id, answerData.answer)"
            >
              <img
                v-if="copiedItemIds.includes(answerData.id)"
                :src="require('@/assets/icons/check_circle_2.svg')"
                alt="copy"
              />
              <img v-else :src="require('@/assets/icons/file_copy.svg')" alt="copy" />
            </v-btn>
          </td>
          <td>
            <div class="summary-answer-impacts">
              <impact-pill
                v-for="(offset, i) in getImpactPillOffsetsFromAnswer(answerData)"
                :key="i"
                class="impact-pill"
                :impact="{ type: offset.type, amount: offset.amount || 0 }"
                theme="filled"
              />
            </div>
          </td>
          <td>{{ userCurrencySymbol + calculateImpactsCosts(answerData.offsets) }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>
<script lang="ts">
import ImpactSettingCard from '@/components/onboarding/ImpactSettingCard.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Clearable, OffsetType } from '@/helpers/interfaces'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import { Decimal } from 'decimal.js'
import type { AutomationOffset } from '@/store/integrations'
import type { ProjectId } from '@/helpers/constants'
import { IMPACT_LOWER_PRICE_LIMIT } from '@/helpers/constants'
import { getCurrencySymbol } from '@/helpers/pricing'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { TranslateResult } from 'vue-i18n'
import type { FormAnswer, FormQuestion } from '@/store/form-question'
import type { Project } from '@/store/projects'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactSettingsPerQuestion',
  emits: ['editingChange', 'save'],
  components: { ImpactPill, ImpactSettingCard },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      isFormValid: true,
      newAnswer: '',
      localValue: { id: '', question: '', answers: [] },
      impactSettings: {},
      editAnswerId: null,
      lastVisibleCardIndex: 0,
      isEditing: false,
      copiedItemIds: [],
      newAnswerInputId: 1,
    } as {
      isFormValid: boolean
      newAnswer: string
      localValue: FormQuestion
      impactSettings: { [key in ProjectId]: number } | object
      editAnswerId: Clearable<string>
      lastVisibleCardIndex: number
      isEditing: boolean
      copiedItemIds: Clearable<string>[]
      newAnswerInputId: number
    }
  },
  methods: {
    getCurrencySymbol,
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`ImpactSettingsPerQuestion.${key}`, params ?? {})
    },
    getImpactPillOffsetsFromAnswer(answer: FormAnswer): AutomationOffset[] {
      return answer.offsets.filter(({ amount }) => !!amount)
    },
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    deleteAnswer(id: string) {
      this.localValue.answers = this.localValue.answers.filter((answer) => answer.id !== id)
      if (this.editAnswerId === id) {
        this.resetEditorState()
      }
    },
    editAnswer(id: string) {
      this.editAnswerId = id
      const answerDataToEdit = this.localValue.answers.find((answer) => answer.id === id)
      if (answerDataToEdit) {
        this.impactSettings = answerDataToEdit.offsets.reduce((acc, { amount, projectId }) => {
          if (!projectId) throw new Error('Project id is missing')
          acc[projectId] = amount
          return acc
        }, {})
        this.newAnswer = answerDataToEdit.answer
      }
    },
    addAnswer() {
      const answerData = {
        answer: this.newAnswer,
        offsets: this.formImpactSettings,
        // temporary id, final id will be set by backend
        // the id is used to identify the answer in the list
        id: Math.random().toString(36).substr(2, 9),
      }

      this.localValue.answers.push(answerData)
      this.resetEditorState()
    },
    saveEditedAnswer() {
      this.localValue.answers.forEach((answer) => {
        if (answer.id === this.editAnswerId) {
          answer.answer = this.newAnswer
          answer.offsets = this.formImpactSettings
        }
      })

      this.resetEditorState()
    },
    saveQuestion() {
      this.resetEditorState()
      this.isEditing = false
      this.$emit('save', this.localValue)
    },
    editQuestion() {
      this.isEditing = true
    },
    cancelEdit() {
      this.resetEditorState()
      if (this.questionData) {
        this.localValue = {
          id: this.questionData.id,
          question: this.questionData.question,
          answers: this.deepCopy(this.questionData.answers) as FormAnswer[],
        }
      }
      this.isEditing = false
    },
    getCostPerAnswerImpacts(index: number): number {
      const impacts = this.localValue.answers[index].offsets
      return this.calculateImpactsCosts(impacts)
    },
    getCostPerAnswerText(index: number): string {
      return `${this.t('cost_per_answer')}: ${
        this.userCurrencySymbol
      }${this.getCostPerAnswerImpacts(index)}`
    },
    calculateImpactsCosts(impacts: AutomationOffset[]) {
      let total = 0
      impacts.forEach(({ amount, projectId }) => {
        total = new Decimal(this.getProjectPriceById(String(projectId)))
          .times(amount || 0)
          .add(total)
          .toNumber()
      })
      return new Decimal(total).toNumber()
    },
    copyToClipboard(id: string, text: string) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedItemIds.push(id)
        setTimeout(() => {
          this.copiedItemIds = this.copiedItemIds.filter((copiedId) => copiedId !== id)
        }, 2000)
      })
    },
    resetEditorState() {
      this.newAnswer = ''
      this.impactSettings = {}
      this.editAnswerId = null
      this.newAnswerInputId++
    },
    onValueChange() {
      if (!this.questionData) return

      this.localValue = {
        id: this.questionData.id,
        question: this.questionData.question,
        answers: this.deepCopy(this.questionData.answers) as FormAnswer[],
      }
    },
    onIsEditingChange() {
      this.$emit('editingChange', this.isEditing)
    },
  },
  computed: {
    getAppProjectIds(): ProjectId[] {
      return this.projects.map(({ projectId }) => projectId)
    },
    questionInputDisabled(): boolean {
      return false
    },
    formTotal(): number {
      return this.calculateImpactsCosts(this.formImpactSettings)
    },
    isFormTotalReachedImpactLowerPrice(): boolean {
      return this.formTotal >= IMPACT_LOWER_PRICE_LIMIT
    },
    isCreateUpdateAnswerButtonDisabled(): boolean {
      return !this.newAnswer || !this.formTotal || !this.isFormTotalReachedImpactLowerPrice
    },
    answersTotal(): number {
      return this.localValue.answers.reduce((acc, { offsets }) => {
        return acc + this.calculateImpactsCosts(offsets)
      }, 0)
    },
    formImpactSettings(): AutomationOffset[] {
      return Object.entries(this.impactSettings).map(([key, value]) => {
        const project = this.getProjectById(key)
        if (!project) throw new Error(`Project with id ${key} not found`)

        return { projectId: key, amount: value, type: project.type } as AutomationOffset
      })
    },
    cartText(): string | TranslateResult {
      // if there is no value set
      if (this.formImpactSettings.every(({ amount }) => !amount)) {
        return ''
      } else {
        let impactText = ''
        // filter the object properties to the properties with values
        const impactSettingWithValues: AutomationOffset[] = this.formImpactSettings.filter(
          ({ amount }) => amount,
        )
        const lastItemIndex = impactSettingWithValues.length - 1
        impactSettingWithValues.forEach(({ amount, projectId }, index) => {
          if (!projectId) throw new Error('Project id is not defined')

          const project = this.getProjectById(projectId)
          if (!project) throw new Error(`Project with id ${projectId} not found`)

          if (amount) {
            const country: string = project.countries?.[0] || ''
            const translation =
              project.type === 'trees'
                ? this.$t(`${project.type}.amount_label_with_country`, { amount, country })
                : this.$t(`${project.type}.amount_label`, { amount })
            if (amount && !index) {
              impactText += translation
            } else if (amount && lastItemIndex === 1) {
              impactText += ', and ' + translation
            } else if (amount && lastItemIndex > 1)
              impactText += (index > 1 ? ', and ' : ', ') + translation
          }
        })
        return this.capitalizeFirstLetter(this.t('text', { amount: impactText }))
      }
    },
    formRef(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement
    },
    saveQuestionButtonDisabled(): boolean {
      return (
        !this.localValue.question ||
        !this.localValue.answers.length ||
        this.answersTotal < IMPACT_LOWER_PRICE_LIMIT
      )
    },
    saveAnswerButtonText(): TranslateResult {
      return !this.isFormTotalReachedImpactLowerPrice
        ? this.t('minimum', { limit: IMPACT_LOWER_PRICE_LIMIT, currency: this.userCurrencySymbol })
        : this.t('new_answer')
    },
    updateAnswerButtonText(): TranslateResult {
      return !this.isFormTotalReachedImpactLowerPrice
        ? this.t('minimum', { limit: IMPACT_LOWER_PRICE_LIMIT, currency: this.userCurrencySymbol })
        : this.t('update_answer')
    },
    hideQuestionEditCancelButton(): boolean {
      return (
        this.$route.path.includes('add-integrations-automations') &&
        !this.questionData?.question &&
        !this.questionData?.answers.length
      )
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
    getProjectPriceById(): (projectId: string) => number {
      return this.$store.getters['getProjectPriceById']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    getProjectById(): (projectId: string) => Project {
      return this.$store.getters['getProjectById']
    },
    projects(): Project[] {
      return this.$store.getters['getAppProjects']
    },
  },
  created() {
    if (this.questionData) {
      this.localValue = {
        id: this.questionData.id,
        question: this.questionData.question,
        answers: this.deepCopy(this.questionData.answers) as FormAnswer[],
      }
    }
    this.isEditing = true
  },
  props: {
    questionData: {
      type: Object as PropType<FormQuestion>,
    },
  },
  watch: {
    questionData: [
      {
        handler: 'onValueChange',
      },
    ],
    isEditing: [
      {
        handler: 'onIsEditingChange',
      },
    ],
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.impact-settings-form,
.question-editor {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.question-input,
.answer-data-row {
  margin-bottom: -12px;
}

.answer-data-row {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.answer-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  gap: 24px;
}

.cost-per-answer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cost-per-answer > p {
  padding: 0 18px;
  font-weight: bold;
}

.answer-impacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 94px;
  gap: 8px;
}

.summary-answer-impacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 8px;
}

.delete-answer-icon {
  color: #c21436;
}

.edit-answer-icon {
  color: var(--ui-green);
}

.impact-settings {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
}

.impact-settings-wrapper {
  position: relative;
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
}

.marker-dot {
  height: 10px;
  width: 10px;
  background-color: var(--gray-light-20);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  border: 1px solid var(--gray-light);
  transition: all ease 0.3s;
}

.marker-dot.visible {
  background-color: var(--ui-green-light);
  border: none;
  transition: all ease 0.3s;
}

.action-button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.action-buttons-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.add-new-answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.plus-icon {
  color: var(--ui-white);
}

.totals-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cart-items-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--gray);
  margin-bottom: 10px;
}

.total-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 12px;
}

.question-summary {
  width: 100%;
  overflow-x: auto;
}

.question-text {
  font-weight: 700;
}

.question-text,
.answer-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
}

.question-data-table {
  width: 100%;
  min-width: 740px;
  border-radius: var(--border-radius-big);
  border: 1px solid var(--gray-light);
}

.question-data-table thead {
  position: relative;
}

.question-data-table thead::after {
  content: '';
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
  background-color: var(--gray-light);
  height: 1px;
}

.question-data-table tbody td:not(.edit-question-td) {
  height: 16px;
}

.question-data-table thead th {
  padding: 16px 8px;
}

.question-data-table tbody td {
  padding: 2px 8px;
}

.question-data-table tbody tr:first-child td {
  padding: 16px 8px 2px;
}

.question-data-table tbody tr:last-child td {
  padding: 2px 8px 16px;
}

.question-data-table tbody td {
  border: none;
}

.question-data-table tbody tr:first-child td.edit-question-td {
  padding: 16px 8px;
}

.copy-button {
  height: 24px;
  width: auto;
}

.impact-pill {
  height: 24px;
  border-radius: 4px !important;
}

.question-th {
  width: 40%;
}

.cost-th {
  min-width: 72px;
}

.cost-th,
.edit-question-th {
  width: 10%;
}

.impact-th,
.copy-th {
  width: 20%;
}

.cancel-edit-button {
  min-width: 100px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .markers {
    display: none;
  }

  .totals-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 24px;
  }

  .cancel-edit-button {
    min-width: 280px;
  }

  .action-button-group.answer-edit-actions {
    flex-direction: row;
  }

  .action-button-group.question-actions {
    gap: 24px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .impact-settings {
    overflow: auto;
  }
}
</style>
