<template>
  <article :class="['project-details-card', impactType, { expanded: headerExpanded }]">
    <div class="project-info">
      <header>
        <h4 class="project-title">
          {{ title }}
        </h4>
      </header>
      <div class="description-wrapper">
        <p class="project-summary">
          {{ $t(`Projects.${project.projectId}.summary`) }}
        </p>
        <p class="project-description">
          {{ $t(`Projects.${project.projectId}.description`) }}
        </p>
        <hr />
        <footer class="card-footer">
          <span v-if="location" class="project-locations">
            <PublicMapPin
              class="location-pin"
              :pin="locationPin"
              is-active
              is-static
              :is-animated="false"
            />
            <span class="location-name">{{ location }}</span>
          </span>
          <a :href="project.link" target="_blank" class="project-cta">
            {{ t('read_more') }}
          </a>
        </footer>
      </div>
    </div>
    <div class="card-image-wrapper">
      <img :src="imageUrl" :alt="impactType + '_image'" class="card-image" />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import PublicMapPin from '@/components/your-impact/PublicMapPin.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'

import type { PropType } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import type { MapPin } from '@/components/your-impact/PublicMapPin.vue'
import type { Project } from '@/store/projects'
import type { AccountImpact } from '@/store'

export default defineComponent({
  name: 'ProjectDetailsCard',
  mixins: [Utils],
  emits: ['toggleExpanded'],
  components: { PublicMapPin },
  data() {
    return {
      headerExpanded: false,
    }
  },
  computed: {
    imageUrl(): string | undefined {
      return this.projects.find(({ projectId }) => projectId === this.project.projectId)?.imageUrl
    },
    impactType(): string {
      return this.projects.find(({ projectId }) => projectId === this.project.projectId)
        ?.type as string
    },
    title(): TranslateResult {
      return this.$t(`Projects.${this.project.projectId}.title`)
    },
    projects(): Project[] {
      return this.$store.getters['getApprovedProjects']
    },
    impact(): AccountImpact | undefined {
      return this.$store.getters['getImpactsByProject'](this.project.projectId)
    },
    locationPin(): MapPin {
      const country =
        this.project.countries && this.project.countries.length > 1 ? this.project.countries[0] : ''

      return {
        index: 0,
        country,
        type: this.project.type,
        projectId: this.project.projectId,
        availability: this.project.availability,
      }
    },
    location(): string | null {
      if (!this.project.countries) return null
      return this.project.countries.length > 1
        ? this.t('multiple_locations')
        : this.project.countries[0]
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string }, amount?: number) {
      return this.$t(`ImpactSettingCardHeader.${key}`, params ?? {}, amount ?? 0)
    },
    toggleHeaderExpanded() {
      this.headerExpanded = !this.headerExpanded
      this.$emit('toggleExpanded', this.headerExpanded)
    },
  },
  props: {
    project: { type: Object as PropType<Project>, required: true },
    isActive: { type: Boolean, default: false },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

$image-mobile-height: 168px;
$image-tablet-height: 204px;
$mobile-section-gap: 8px;
$desktop-section-gap: 10px;

.project-details-card {
  padding: 8px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  height: 100%;
  background-color: var(--gray-light-F1);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    flex-direction: row;
    gap: 16px;
    padding: 16px;
  }
}

.card-image-wrapper {
  width: 100%;
  flex-basis: $image-mobile-height;
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  text-align: center;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-basis: $image-tablet-height;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    flex-basis: auto;
    width: 300px;
    height: 100%;
    padding: 7px 0;
  }

  .card-image {
    border-radius: 4px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    object-position: 50% 50%;
  }
}

.description-wrapper {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  gap: $mobile-section-gap;
  overflow: hidden;

  .project-summary {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    margin-bottom: 0;
    flex-shrink: 0;
    flex-grow: 1;
    display: block;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .project-description {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    flex-shrink: 1;
    flex-grow: 0;
    overflow-y: scroll;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      font-size: 14px;
      line-height: 17px;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  hr {
    border: 0px solid var(--gray-light-A9);
    border-top-width: 0.5px;
  }
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 1;
  gap: $mobile-section-gap;
  flex-basis: calc(100% - #{$image-mobile-height} - #{$mobile-section-gap});
  overflow: hidden;
  height: auto;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-basis: calc(100% - #{$image-tablet-height} - #{$mobile-section-gap});
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    gap: $desktop-section-gap;
    flex-basis: auto;
    height: 100%;
  }

  header {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    flex-shrink: 0;
    flex-grow: 0;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      flex-direction: row;
      gap: 12px;
    }

    .project-title {
      line-height: normal;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin-bottom: 8px;
      margin-bottom: 0;

      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  footer {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-shrink: 0;
    flex-grow: 1;

    .project-cta {
      color: var(--font-color-link);
      font-size: 10px;
      line-height: 12px;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .project-locations {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;

      .location-pin {
        width: 20px;
        height: 20px;
      }

      .location-name {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
      }
    }
  }
}
</style>
