<template>
  <svg
    :id="`public-map-pin-${pin.country}-${pin.type}${isStatic ? 'static' : ''}`"
    :class="[
      'public-map-pin',
      { 'is-active': isActive },
      { 'is-animated': isAnimated },
      { 'is-static': isStatic },
    ]"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53 55"
    fill="none"
  >
    <g clip-path="url(#clip0_4558_28673)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5 3C35.6093 3 46 9.94588 46 23.1882C46 31.9976 39.5093 41.2871 26.5 51C13.4907 41.2871 7 31.9976 7 23.1882C7 9.94588 17.3907 3 26.5 3Z"
        :fill="colorSettings[pin.type]"
      />
      <component :is="componentForImpactType" />
    </g>
  </svg>
</template>

<script lang="ts">
import type { Component, PropType } from 'vue'
import { defineComponent } from 'vue'
import { IMPACT_TYPE_INACTIVE_PIN_COLOR_MAP, IMPACT_TYPE_PIN_COLOR_MAP } from '@/helpers/projects'

import type { OffsetType } from '@/helpers/interfaces'
import CarbonPinPath from '@/components/icons/map-pin-paths/CarbonPinPath.vue'
import KelpPinPath from '@/components/icons/map-pin-paths/KelpPinPath.vue'
import PlasticPinPath from '@/components/icons/map-pin-paths/PlasticPinPath.vue'
import TreesPinPath from '@/components/icons/map-pin-paths/TreesPinPath.vue'
import WaterPinPath from '@/components/icons/map-pin-paths/WaterPinPath.vue'

export interface MapPin {
  index: number
  country: string
  type: OffsetType
  projectId: string
  availability: number | null
  link?: string
}

const TypeToPinMapping: Record<OffsetType, Component> = {
  carbon: CarbonPinPath,
  trees: TreesPinPath,
  plastic: PlasticPinPath,
  kelp: KelpPinPath,
  water: WaterPinPath,
}

export default defineComponent({
  name: 'PublicMapPin',
  components: {
    CarbonPinPath,
    KelpPinPath,
    PlasticPinPath,
    TreesPinPath,
    WaterPinPath,
  },
  props: {
    pin: { type: Object as PropType<MapPin>, required: true },
    isActive: { type: Boolean, default: false },
    isAnimated: { type: Boolean, default: true },
    isStatic: { type: Boolean, default: false },
  },
  computed: {
    colorSettings(): Record<OffsetType, string> {
      return this.isActive ? IMPACT_TYPE_PIN_COLOR_MAP : IMPACT_TYPE_INACTIVE_PIN_COLOR_MAP
    },
    componentForImpactType(): Component {
      return TypeToPinMapping[this.pin.type]
    },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.public-map-pin {
  position: absolute;
  opacity: 0;
  height: 20px;
  width: 20px;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.is-static {
    cursor: auto;
    position: relative;
    opacity: 1;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 45px;
    width: 45px;
  }

  &.is-active {
    width: 25px;
    height: 25px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 50px;
      width: 50px;
    }
  }

  &.is-animated {
    animation-name: drop-in;
    animation-duration: 1s;
  }

  &:nth-child(1) {
    animation-delay: 0.1s;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }
}

@keyframes drop-in {
  0% {
    transform: translateY(-150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
