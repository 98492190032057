import { defineComponent } from 'vue'
import type {
  ActiveCampaignTrackingOption,
  FacebookTrackingOption,
  LinkedinTrackingOption,
} from '@/helpers/constants'
import type { Clearable, Currency } from '@/helpers/interfaces'
import { firebaseAnalytics } from '@/auth/firebaseConfig.js'
import type {
  PersonalSubscriptionType,
  SubscriptionType,
  TechnicalAndBusinessSubscriptionType,
} from '@/helpers/pricing'
import { getCurrencyCode, getPlanPrice } from '@/helpers/pricing'

export const SetUpSocialMediaTracking = defineComponent({
  methods: {
    initFacebookTracking() {
      if (process.env.VUE_APP_ENVIRONMENT === 'production') {
        window.fbq('init', '215551324125976')
      }
    },
    setUpFacebookTracking(option: FacebookTrackingOption) {
      if (process.env.VUE_APP_ENVIRONMENT === 'production') {
        switch (option) {
          case 'InitiateCheckout':
            // started to sign-up but may not complete
            window.fbq('track', 'InitiateCheckout')
            break
          case 'Subscribe':
            // completed sign-up
            window.fbq('track', 'Subscribe')
            break
          case 'ViewContent':
            // pricing page
            window.fbq('track', 'ViewContent')
            break
          case 'PageView':
            // page view
            window.fbq('track', 'PageView')
        }
      }
    },
    setupActiveCampaignTracking(option: ActiveCampaignTrackingOption) {
      if (process.env.VUE_APP_ENVIRONMENT === 'production') {
        switch (option) {
          case 'subscribe':
            window.vgo('setAccount', '1001918288')
            window.vgo('setTrackByDefault', true)
            window.vgo('process')
            break
        }
      }
    },
    setupLinkedInTracking(option: LinkedinTrackingOption) {
      if (process.env.VUE_APP_ENVIRONMENT === 'production') {
        switch (option) {
          case 'subscribe':
            window.lintrk('track', { conversion_id: 13113050 })
            break
        }
      }
    },
    setupPurchaseEvent(
      product: SubscriptionType,
      currency: Currency,
      transactionId: Clearable<string>,
    ) {
      if (process.env.VUE_APP_ENVIRONMENT === 'production' && product && currency) {
        firebaseAnalytics.logEvent('purchase', {
          transaction_id: transactionId,
          currency: getCurrencyCode(currency),
          value: getPlanPrice(
            product as PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType,
            currency,
          ),
        })
        window.gtag('event', 'conversion', {
          send_to: 'AW-11034878946/PeQ5CPvL9O8ZEOLH640p',
          transaction_id: transactionId,
          currency: getCurrencyCode(currency),
          value: getPlanPrice(
            product as PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType,
            currency,
          ),
        })
      }
    },
    setupBeginCheckoutEvent(product: SubscriptionType, currency: Currency) {
      if (process.env.VUE_APP_ENVIRONMENT === 'production' && product && currency) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-11034878946/iswHCMvr_-8ZEOLH640p',
          transaction_id: '',
          currency: getCurrencyCode(currency),
          value: getPlanPrice(
            product as PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType,
            currency,
          ),
        })
      }
    },
  },
})
